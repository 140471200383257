import * as React from "react";
import { Fragment, useEffect, useState, useCallback, useParams } from "react";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import api from "../../utils/api";
import Table from "../../parts/Table/Table";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ActiveUserPopup from  "./ActiveUserPopup";
import "./../Users/User.css";
import SettingsUserModal from './SettingsUserModal'
import DeleteRow from "../../parts/DeleteRow";
import moment from 'moment'
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import AppsIcon from "@mui/icons-material/Apps";


const DOC_TYPE = {
  1: "SSN",
  2: "PASSPORT",
};
const roleEunm  = {
  1:'ADMIN',
  2:'SALES HEAD',
  3:'SALES'
}
const STATUS = {
  1:<Button variant="contained" color="success">
  Active
</Button>,
  2:  <Button variant="outlined" color="error">
  Deactive
</Button>,
};

const EMAIL_VERIFIED = {
  1: <CheckIcon fontSize="small" color="green" />,
  0: <CloseIcon fontSize="small" color="red"/>,
};

const PHONE_VERIFIED = {
  1: <CheckIcon fontSize="small" color="green"/>,
  0:  <CloseIcon fontSize="small" color="red"/>,
};

const IMAGE_VERIFIED = {
  1: <CheckIcon fontSize="small" color="green"/>,
  0:  <CloseIcon fontSize="small" color="red"/>,
};

export default function Application() {

  const STATUS = {
    1:<Button variant="contained" color="success">
    Active
  </Button>,
    2:  <Button variant="outlined" color="error">
    Deactive
  </Button>,
  };

  const headCells = [
    {
      field: "name",
      headerName: "Name",
     
    },
     {
      field:"email",
      headerName:"E-Mail",
    
     },
    {
      field: "role",
      filter: true,
      headerName: "Role",
      valueGetter:(params)=>{
        return roleEunm[params.data.role]
      }
   
    },
    {
      cellRenderer: (params) => {
     return params.data.status === 1?'Active':'Deavtive'
      },
      field: "status",
      headerName: "Status",
    }, 
    {
      cellRenderer: (params) => {
     return params.data.status === 1?(<Button variant="outlined" color="error" onClick={()=>{
      setShowActivePopup(params.data)
      setData(params.data)
    }}>Deactive</Button>):(<Button variant="contained" color="success"onClick={()=>{
      setShowActivePopup(params.data)
      setData(params.data)
    }}> Active</Button>)
      },
      field: "status",
      headerName: "Action",
    }  
  
  ];
  

  const [data,setData] = useState({})
  const [showPopup,setShowPopup] = useState(false)
  const [showActivePopup,setShowActivePopup] = useState(false)
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openCreatePanel, setOpenCreatePanel] = useState(false)
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  // const navigate = useNavigate();


//   const fetchApplications = (cb) => {
//     api.get(`/account?offset=${page}&limit=${limit}`).then(( {accounts} ) => {
//       setRows(accounts);
//       cb && cb()
//     }).catch(console.log)
//   }
const fetchUser = (cb) => {
  api.get('/users').then((settings) => {
    setRows(settings);
      cb && cb()
  }).catch(console.log)
}
  
const changeStatus=(id,status)=>{
  api.patch(`/${id}`, {
       status:status
}).then(() => {
    toast.success("Succes!")
     fetchUser()
     setShowActivePopup(false)
}).catch(() => {
    toast.error("Error!");
})
}

  const handleOpenPanel = (row) => {
    setData(row.data)
    setShowPopup(true);
  };
console.log(rows)
  return (
    <Box className="users">
      <Box className="filterClients">
        <Box className="filterName">
          <Box className="filterIcon">
            <AppsIcon fontSize="large" />
          </Box>
          <Typography variant="h5">Users</Typography>
        </Box>
      </Box>
      <Button sx={{marginRight:'10px',marginLeft:'10px'}} color='inherit' className='createButton' onClick={()=>{ setData(null) 
      setShowPopup(true)}} variant='outlined'
                >Add
                </Button>
          {showPopup?<SettingsUserModal
              data = {data}
              fetchUser={fetchUser}
              setShowPopup = {setShowPopup}
             title="Add User"
             button="Add"
        
          />:null}
           {showActivePopup?<ActiveUserPopup
              data = {data}
              changeStatus = {changeStatus}
              fetchUser={fetchUser}
              setShowPopup = {setShowActivePopup}
             title="Add User"
             button="Add"
        
          />:null}
      <Table
        data = {data}
        setIsOpenPanel={setIsOpenPanel}
        isOpenPanel={isOpenPanel}
        rows={rows}
        cells={headCells}
        onFilterChange={fetchUser}
        showCheckBox={false}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rowClick={handleOpenPanel}
        searchType={headCells.searchType}
        id="appls"
        // onFilterChange={}
      />

    </Box>
  );
}
