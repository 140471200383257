import * as React from "react";
import { Fragment, useEffect, useState, useCallback, useParams } from "react";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import api from "../../utils/api";
import Table from "../../parts/Table/Table";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import "./User.css";
import DeleteRow from "../../parts/DeleteRow";
import moment from 'moment'
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import AppsIcon from "@mui/icons-material/Apps";


const DOC_TYPE = {
  1: "SSN",
  2: "PASSPORT",
};

const STATUS = {
  1: "ACTIVE",
  0: "INACTIVE",
};

const EMAIL_VERIFIED = {
  1: <CheckIcon fontSize="small" color="green" />,
  0: <CloseIcon fontSize="small" color="red"/>,
};

const PHONE_VERIFIED = {
  1: <CheckIcon fontSize="small" color="green"/>,
  0:  <CloseIcon fontSize="small" color="red"/>,
};

const IMAGE_VERIFIED = {
  1: <CheckIcon fontSize="small" color="green"/>,
  0:  <CloseIcon fontSize="small" color="red"/>,
};

const headCells = [
  {
    field: "accountId",
    headerName: "Account Id",
  },
   {
    field:"createdAt",
    valueGetter:(p)=>{
      return moment(p.data.createdAt).format("YYYY/MM/DD")
    }
   },
  {
    field: "docNumber",
    filter: true,
    headerName: "Document Number",
  },

  {
    cellEditorParams: {
      values: Object.keys(DOC_TYPE),
    },
    filter: "agSetColumnFilter",
 
    filterParams: {
      valueFormatter: (params) => {
        return DOC_TYPE[params.value];
      },
    },
    valueFormatter: (params) => {
      return DOC_TYPE[params.value];
    },
    field: "docType",
    headerName: "Document Type",
  },

  {

    cellRenderer: (params) => {
      return <div className="row">{params.data.emailVerified?(<CheckIcon sx={{paddingRight:'4px'}} fontSize="small" color="success" />) :(<CloseIcon fontSize="small" color="error"/>) }{params.value}</div>;
    },
    field: `email`,
    filter: true,
    headerName: "E-mail",
  },

  // {
  //   cellEditorParams: {
  //     values: Object.keys(IMAGE_VERIFIED),
  //   },
  //   filter: "agSetColumnFilter",
 
  //   filterParams: {
  //     valueFormatter: (params) => {
  //       return IMAGE_VERIFIED[params.value];
  //     },
  //   },
  //   valueFormatter: (params) => {
  //     return IMAGE_VERIFIED[params.value];
  //   },
  //   field: "imageVerified",
  //   headerName: "Image Verified",
  // },

  {
    cellRenderer: (params) => {
      return <div className="row">{params.data.phoneVerified?(<CheckIcon sx={{paddingRight:'4px'}} fontSize="small" color="success" />) :(<CloseIcon fontSize="small" color="error"/>) }{params.value}</div>;
    },
    field: `phone`,
    filter: true,
    headerName: "Phone",
  },
  {
    cellRenderer: (params) => {
      console.log(params)
      return <div className="row">{params.data.status?(<CheckIcon sx={{paddingRight:'4px'}} fontSize="small" color="success" />) :(<CloseIcon fontSize="small" color="error"/>) }{''}</div>;
    },
    field: "status",
    headerName: "Status",
  },
  

];

export default function Application() {
  const [cells, setCells] = useState([]);
  const [data,setData] = useState({})
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openCreatePanel, setOpenCreatePanel] = useState(false)
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();


  const fetchApplications = (cb) => {
    api.get(`/account?offset=${page}&limit=${limit}`).then(( {accounts} ) => {
      setRows(accounts);
      cb && cb()
    }).catch(console.log)
  }
  
  

  const handleOpenPanel = (row) => {
    setData(row.data)
    setIsOpenPanel(!isOpenPanel);
  };

  return (
    <Box className="users">
      <Box className="filterClients">
        <Box className="filterName">
          <Box className="filterIcon">
            <AppsIcon fontSize="large" />
          </Box>
          <Typography variant="h5">Applications</Typography>
        </Box>
      </Box>
      <Table
        data = {data}
        setIsOpenPanel={setIsOpenPanel}
        isOpenPanel={isOpenPanel}
        rows={rows}
        cells={headCells}
        showCheckBox={false}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rowClick={handleOpenPanel}
        searchType={headCells.searchType}
        id="appls"
        onFilterChange={fetchApplications}
      />

    </Box>
  );
}
