import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import InputLabel from '@mui/material/InputLabel';
import './SettingsUserModal.css';
import { useState } from "react";
import * as Validator from "../../utils/validation";
import api from '../../utils/api'
import Switch from '@mui/material/Switch';
import { isEditable } from '@testing-library/user-event/dist/utils';

export default function ActiveUserPopup(props) {

    const [isValidNumber, setValidNumber] = useState(true)
    const [name, setName] = useState(props.data?.name||'')
    const [email, setEmail] = useState(props.data?.email||'')
    const [role, setRole] = useState(props.data?.role||1)
    const [isValidUrl, setIsValidUrl] = useState(true)

    return <Box className='overlead'>

        <Box className='form' component="form" onSubmit={props.submit}>

            <Button className='modalCloseButton' color='inherit' onClick={()=>props.setShowPopup(false)} variant="text">
                X
            </Button>
            <Typography variant='h5'>{'Update Status'}</Typography>
               <h1>Are You sure?</h1> 
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button color='inherit' 
                onClick={()=>props.setShowPopup(false)}
                    sx={{ width: '30%', margin: '10px' }} variant='outlined'
                >{'No'}</Button>
                <Button color='inherit' 
                onClick={()=>props.changeStatus(props.data.id,props.data.status===1?0:1)}
                    sx={{ width: '30%', margin: '10px' }} variant='outlined'
                >{'Yes'}</Button>
            </Box>
        </Box>
    </Box>
}