import * as React from "react";
import Modal from "../../pages/Users/Modal";
import { useCallback, useEffect, useRef,useMemo } from "react"; // Optional theme CSS
import Paper from "@mui/material/Paper";
import "./Table.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { InfiniteRowModel } from 'ag-grid-community';
import { LicenseManager } from "ag-grid-enterprise";
import LinearProgress from '@mui/material/LinearProgress';
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";

LicenseManager.setLicenseKey(
  process.env.REACT_APP_AG_GRID + "6375ce4e397ec3b0caec647570d74f25"
);

export default function ReactVirtualizedTable(props) {
  const {
    rows = [],
    cells: _cells = [],
    setSelectedRows = () => {},
    selectedRows = [],
    rowClick = () => {
      console.log('a')
    },
    onDelete = () => {},
    onFilterChange = () => {},
    id,
    height = "calc(100vh - 400px)",
  } = props;
  const gridRef = useRef();

  useEffect(() => {
    onFilterChange(()=>{
      gridRef.current.api.sizeColumnsToFit()
      gridRef.columnApi.autoSizeColumns()
    });
    
  }, []);
  
  useEffect(() => {
    onFilterChange(() => {
        setTimeout(() => {
            if (gridRef.current) {
                gridRef.current.columnApi.autoSizeColumns();
                gridRef.current.api.sizeColumnsToFit();
            }
        }, 100);
    });
}, []);

const defaultColDef = useMemo(() => ({
  flex: 1,
  cellDataType: false,
  
}), []);
  useEffect(() => {
    if (gridRef && selectedRows.length)
      gridRef.current.api.forEachNode((node) =>
        node.setSelected(!!node.data && selectedRows.includes(node.data.id))
      );
  }, [selectedRows]);


  const onSelectionChanged = useCallback((e) => {
    const selectedCars = e.api.getSelectedNodes().map((r) => r.data.id);
    setSelectedRows(selectedCars);
  }, []);

  return (
    <Paper style={{ height, marginTop: '30px',paddingRight:"10px",paddingLeft:'10px'}}>
      <AgGridReact
        colResizeDefault={'shift'}
        ref={gridRef}
        className="ag-theme-alpine"
        columnDefs={_cells}
        rowData={rows}
        rowGroup={false}
      //   overlayNoRowsTemplate={
      //     `
      //     <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' width="200">
      //         <circle fill='#2e7d32' stroke='#2e7d32' stroke-width='2' r='5' cx="60" cy="65">
      //             <animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"/>
      //         </circle>
      //         <circle fill="#2e7d32" stroke="#2e7d32" stroke-width="2" r="5" cx="100" cy="65">
      //             <animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"/>
      //         </circle>
      //         <circle fill="#2e7d32" stroke="#2e7d32" stroke-width="2" r="5" cx="140" cy="65">
      //             <animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"/>
      //         </circle>
      //     </svg>
      // `
      //   }
        pagination={true}
        defaultColDef={defaultColDef}
        paginateChildRows={true}
        suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
        onRowDoubleClicked={rowClick}
        
      />
      {props.isOpenPanel?(<Modal data = {props.data} setIsOpenPanel={props.setIsOpenPanel} />):null}
    </Paper>
  );
}
