import moment from"moment";

export const correctDate =(param)=>{
        const a = moment.utc(param)
        return a.format('YYYY-MM-DD HH:mm')
}

export const correctDateWithoutMinutes =(param)=>{
        const a = moment.utc(param)
        return a.format('YYYY-MM-DD')
}


