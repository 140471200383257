import * as React from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Table from '../../parts/Table/Table';
import api from "../../utils/api";
import { toast } from 'react-toastify';
import SettingsModal from './SettingsModal';
import './Settings.css'
import Filter from './Filter';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import {correctDate} from './../../utils/date'


export const STATUS_TYPE = {
    0: "INACTIVE",
    1: "ACTIVE",
};

const Source_Enum = {
    1:'API',
    2:'iFrame',
    3:'Website',
    4:'CRM'
}
const Settings = React.memo(() => {
    const {t} = useTranslation()
    const [settingCells, setSettingCells] = useState([])
    const [isOpenNodeCreate, setIsOpenNodeCreate] = useState(false)
    const [isOpenNodeEdit, setIsOpenNodeEdit] = useState(false)
    const [leadersRow, setLeadersRows] = useState([])
    const [selectedRows, setSelectedRows] = useState({});
    const [load,isLoad] = useState(true)
    const [rows, setRows] = useState([]);
    const [settingRow, setSettingRow] = useState(null)
    const [isOpenPanel, setIsOpenPanel] = useState(false);
    const [isOpenImport, setIsOpenImport] = useState(false);
    const [files, setFiles] = useState(false);
    const navigate = useNavigate();
    const additionalData = {};
    const _settingCells = [
        {
            headerName:t('firstTableCell1'),
            field: 'id',
            label: 'ID',
            maxWidth:'200',
            valueGetter:(params)=>{
                return params.data.id?params.data.id:'null'
            },
            width:'50px',
            sortable: true,
            filter: true,
        },
        {
            headerName:'NID',
            filed:'documentNumber',
            label:"nid",
            valueGetter:(params)=>{
                return params.data.documentNumber
            },
            filter: true,
        },
        {
            headerName:t('firstTableCell2'),
            field: 'firstNameEng',
            label: 'Name',
            valueGetter:(params)=>{
                return params.data.firstNameEng?params.data.firstNameEng:'null'
            },
            sortable: true,
            filter: true,
        },
        // {
        //     field: 'status',
        //     sortable: true,
        //     filter: true,
        //     cellRenderer: ({ data: { status } }) => {
        //         return STATUS_TYPE[status]
        //     }
        // },
        {
            headerName:t('firstTableCell3'),
            field: 'mobileNumber',
            label: 'Mobile',
            valueGetter:(params)=>{
                return params.data.mobileNumber?params.data.mobileNumber:'null'
            },
            sortable: true,
            filter: true,
        },
        {
            headerName:t('firstTableCell4'),
            field: 'fullNameAr',
            label: 'Full Name Ar',
            valueGetter:(params)=>{
                return params.data.fullNameAr?params.data.fullNameAr:'null'
            },
            sortable: true,
            filter: true,
        },
        {
            headerName:t('firstTableCell6'),
            field: 'source',
            valueGetter:(params)=>{
                return Source_Enum[params?.data?.source]
            },
            label: 'source',
            sortable: true,
            filter: true,
        },
        {
            headerName:t('firstTableCell5'),
            field: 'email',
            label: 'Redirect URL',
            valueGetter:(params)=>{
                return params.data.email?params.data.email:'null'
            },
            sortable: true,
            filter: true,
        },
        {
            // headerName:t('firstTableCell5'),
            field: 'updateAt',
            valueGetter:(params)=>{
                return correctDate(params.data.updateAt)
            },
            sortable: true,
            filter: true,
        },
      
    ];
    

//     const handleFileChange = (event) => {
//     setFiles(event.target.files);
//   };

//     const handleCreateNodePanel = () => {
//         setIsOpenNodeCreate(!isOpenNodeCreate)
//     }

//     const handleImportPanel = () => {
//         setIsOpenImport(!isOpenImport)
//     }
    console.log(leadersRow)
    // const fetchLeaders = (cb) => {
    //     api.get(`/leads?filter=${'{"limit":"100"}'}`).then((settings) => {
    //         setLeadersRows(settings.leads?.sort((a,b)=>new Date(b.updateAt) - new Date(a.updateAt)));
    //         isLoad(false)
    //         cb && cb()
    //     }).catch(console.log)
    // }

    // useEffect(()=>{
    //     fetchLeaders()
    // },[])
//     useEffect(() => {
//         if (_settingCells.length !== 6) {
//             _settingCells.push({
//                 field: 'delete',
//                 headerName: 'Actions',
//                 cellRenderer: ({ data }) => {
//                     return <PatchRow
//                         _settingCells={_settingCells}
//                         settingRow={settingRow}
//                         isOpenNodeEdit={isOpenNodeEdit}
//                         afterDelete={fetchSettings}
//                         fetchSettings={fetchSettings}
//                         handleCreateNodePanel={handleCreateNodePanel}
//                         handleEditNodePanel={(e) => handleEditNodePanel( e, data)}
//                         id={data.id}
//                         link='/partner'
//                     />
//                 }
//             })
//         }
//         setSettingCells(_settingCells)
//         return () => {
//             _settingCells.pop()
//             setSettingCells(_settingCells)
//         }
//     }, [])

//     const handleCreate = (event) => {
//         event.preventDefault()
//         const { target: { name: nameValue } } = event
//         const { target: { redirectUrl: redirectUrlValue } } = event


//         const name = nameValue.value;
//         const redirectUrl = redirectUrlValue.value

//         let body = {
//             name,
//             redirectUrl
//         }


//         api.post('/partner', body).then(() => {
//             fetchSettings()
//             handleCreateNodePanel()
//             toast.success("Succes!")
//         }).catch(() => {
//             toast.error("Error!");
//         })
//     }

//     const handleSubmit = React.useCallback(
//         (e) => {
//           e.preventDefault();
//           const headers = {
//             "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
//           };
    
//           const body = new FormData();
//           body.append("document", files[0]);
    
//           api
//             .post("/slides", body, {
//               headers,
//             })
//             .then(() => {
//               toast.success("Success!");
//             })
//             .catch((e) => {
//               toast.error("Passed wrong format of document!");
//             });
//         },
//         [files]
//       );

    const handleLeaderInfo = ({data: row}) => {
        navigate(`/lead/${row.id}`);
    };

//     const handleNodeEdit = (event) => {
//         event.preventDefault()
//         const { target: { status: statusValue} } = event
//         const { target: { redirectUrl: redirectUrlValue} } = event
    
//         const status = parseInt(statusValue.value);
//         const redirectUrl = redirectUrlValue.value;
    
//         let body = {
//           status,
//           redirectUrl
//         }
    
//             api.put(`/partner/${settingRow.id}`, body , body.additionalData).then(() => {
//                 fetchSettings()
//                 handleEditNodePanel()
//                 toast.success("Succes!")
//             }).catch(() => {
//                 toast.error("Error!");
//             })
//     }

//     const handleEditNodePanel = (event, data) => {
//         if(event){
//             event.preventDefault();
//             event.stopPropagation()
//         }
//         setIsOpenNodeEdit(!!data)
//         setIsOpenNodeCreate(false)
//         setSettingRow(data)
//     }
//    console.log(leadersRow)
    return <Box className='cars'>
        <Box className='typesWrapper'>
            <Box className='contactWrapper'>
                <Box className='import'>
            <Box className="importIcon">
          <PersonIcon fontSize="large" />
        </Box>
                <Box className='typeWrapper'>
                    <Typography variant="h5">{t('navText1')}</Typography>
                </Box>
                </Box>
                <Filter setLeads={setLeadersRows}/>
                <Box sx={{display:'flex',width:'100%',justifyContent:'end',padding:'10px', paddingBottom:'0'}}>
                    <Button variant="contained"
                    color='success'
                    onClick={()=>navigate('/create-lead/0')}
                    sx={{margin:'20px',marginTop:'0',marginBottom:'0',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
                        background:'var(--Gray-100, #F2F4F7)'
                   }}}>
                        Create lead
                    </Button>
                </Box>
           
              
          
                {/* {isOpenNodeCreate ? <SettingsModal
                    title="Add Partner"
                    create={handleCreateNodePanel}
                    submit={handleCreate}
                    button="Add"
                    settingRow={settingRow}
                /> : null}
                {isOpenImport ? <ImportModal
                    title="Import Images"
                    create={handleImportPanel}
                    submit={handleSubmit}
                    fileChange={handleFileChange}
                    button="Accept"
                /> : null}
                <div className="edit">
                    {isOpenNodeEdit ? <SettingsModal
                        title="Edit Partner"
                        create={handleEditNodePanel}
                        submit={handleNodeEdit}
                        button="Edit"
                        isEditable={true}
                        _settingCells={_settingCells}
                        settingRow={settingRow}
                        settingRows={settingRows}
                    /> : null}
                </div> */}

             <Table className='contactTable'
                    showCheckBox={false}
                    rows={leadersRow||[]}
                    cells={_settingCells}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    isConfigurable={false}
                    rowClick={handleLeaderInfo}
                    id='contacts_1'
                    // onFilterChange={fetchSettings}
                />
            </Box>
        </Box>
    </Box>
});

export default Settings;
