import React from 'react'
import './component.css'

function Input({name,disable,value,style}) {
  return (
    <div className='input' style={style}>
        <span>{name}</span>
        <input  value={value} disabled={disable}/>
    </div>
  )
}

export default Input