import React, { useEffect, useState } from "react";
import "./CreateLead.css";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { loanTypesAb, nationalityListWithOptions, salaryOptionsAb } from "./../../utils/options";
import { nationalityListWithOptionsArabic } from "./../../utils/options";
import { MilitaryArray } from "./../../utils/options";
import { alpha, styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import uq from '@umalqura/core';
import { salaryOptions } from "./../../utils/options";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import api from "./../../utils/api";
import { createSvgIcon } from "@mui/material/utils";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);
const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 
];
const months = [
  { value: 1, label: "al-Muḥarram" },
  { value: 2, label: "Ṣafar" },
  { value: 3, label: "Rabīʿ al-ʾAwwal" },
  { value: 4, label: "Rabīʿ ath-Thānī" },
  { value: 5, label: "Jumādā al-ʾŪlā" },
  { value: 6, label: "Jumādā ath-Thāniyah" },
  { value: 7, label: "Rajab" },
  { value: 8, label: "Shaʿbān" },
  { value: 9, label: "Ramaḍān" },
  { value: 10, label: "Shawwāl" },
  { value: 11, label: "Ḏū al-Qaʿdah" },
  { value: 12, label: "Ḏū al-Ḥijjah" },
];
const  mounthAb = [
  { value: 1, label: "محرم" },
  { value: 2, label: "صفر" },
  { value: 3, label: "ربيع الأول" },
  { value: 4, label: "ربيع الثاني" },
  { value: 5, label: "جمادى الأولى" },
  { value: 6, label: "جمادى الثانية" },
  { value: 7, label: "رجب" },
  { value: 8, label: "شعبان" },
  { value: 9, label: "رمضان" },
  { value: 10, label: "شوال" },
  { value: 11, label: "ذو القعدة" },
  { value: 12, label: "ذو الحجة" },
]
const simplifiedTypes = [
  { value: 1, name: 'apartment' },
  { value: 2, name: 'land' },
  { value: 3, name: 'villa' },
  { value: 4, name: 'floor' },
  { value: 5, name: 'other' }
];

const workSector = [
  { value: 1, label: "Government" },
  { value: 2, label: "Military" },
  { value: 3, label: "Private (SME)" },
  { value: 4, label: "Private (Enterprise)" },
  { value: 5, label: "Retired" },
];
const obligationsEnum = [
  { value: 1, label: "Personal" },
  { value: 2, label: "Car" },
  { value: 3, label: "Mortgage" },
  { value: 4, label: "Credit card" },
  { value: 5, label: "Emkan" },
  { value: 6, label: "Total" },
];
const years = () => {
  let arr = [];
  for (let i = 1318; i <= uq(new Date())?._hy - 18; i++) {
    arr.push(i);
  }
  return arr.sort((a, b) => b - a);
};  
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    left:"auto"
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const fullYear = () => {
  let arr = [];
  for (let i = 1318; i <= uq(new Date())?._hy; i++) {
    arr.push(i);
  }
  return arr.sort((a, b) => b - a);
};  
function CreateLead() {
  const {id} = useParams()
  const {t,i18n} = useTranslation()
  console.log('id',id)
  const navigat = useNavigate()
  const [personalINformation, setPersonalINformation] = useState(null);
  const [work, setWork] = useState(null);
  const [obligationsData, setObligationData] = useState({defaultSimah: false});
  const [mortgage, setMorgage] = useState(null);
  const [day, setDay] = useState("");
  const [mounth, setMounth] = useState("");
  const [year, setYear] = useState("");
  const [indentified, setIndentified] = useState(false);
  const [debt, setDebt] = useState(false);
  const [obligations, setObligations] = useState([]);
  const [simah,setSimah] = useState(true)

  useEffect(()=>{
    RenderData()
  },[id])
  function calculateAge(birthDate) {
    const currentDate = uq(new Date());
    const dob = new Date(birthDate);

    let age = currentDate._hy - dob.getFullYear();

    const currentMonth = currentDate._hm;
    const birthMonth = dob.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate._hd < dob.getDate())
    ) {
      age = age - 1;
    }

    return age;
  }
  const dayFormat = (day, mounth, year) => {
    return `${year}-${mounth}-${day}`;
  };
  const changeObligationData = (id, name, value) => {
    const obj = obligations.map((i) => {
      if (i.id == id) {
        return { ...i, [name]: value };
      }
      return i;
    });
    setObligations(obj);
  };
  const collectDate = (mm, yyyy) => {
    return `${yyyy}-${mm}-${"1"}`;
  };
  const normaliseWork = (work) => {
    let obj = {};
    if (work) {
      for (let key in work) {
        if (work[key]) {
          obj[key] = work[key];
        }
      }
      return obj;
    } else {
      return work;
    }
  };
  const normalizeBody = (body) => {
    let obj = {};
    for (let key in body) {
      if (body[key]) {
        obj[key] = body[key];
      }
    }
    return obj;
  };
  const normaliseObligationData = (obligations) => {
    if (obligations.length) {
      return {
        ...obligationsData,
        obligations: obligations.map((i) => {
          return {
            
            amount: i.amount,
            loanTotalAmount:i.loanTotalAmount,
            loanDuration: i.loanDuration,
            loanStartDateHijri: i.loanStartDateHijri,
            obligationType: i.obligationType,
          };
        }),
      };
    } else {
      return obligationsData;
    }
  };
  const RenderData = () =>{
    api.get(`/leads/${id}`).then((_user) => {
      setPersonalINformation(_user)
      setDay(+_user?.dobHijri?.split('-')?.[2])
      setMounth(+_user?.dobHijri?.split('-')?.[1])
      setYear(+_user?.dobHijri?.split('-')?.[0])
      setObligations(_user?.obligations)
      setWork(_user?.works?.[0])
      setSimah(!_user?.defaultSimah)
      setMorgage(_user?.mortgages?.[0])
 
    }).catch(console.log)
  }
  useEffect(() => {
    if (day && months && year) {
      setPersonalINformation({
        ...personalINformation,
        dobHijri: dayFormat(day, mounth, year),
      });
    }
  }, [day, months, year]);
 const salaryValidation = (net,basic,alow) =>{
  console.log('sxx',net,basic,alow)
        if(+net>=+basic+alow){
          return false
        }else{
          return true
        }
 }
 const obligationsValidation = (net,obiligation) =>{
      let sum = obiligation?.reduce(
        (accumulator, currentValue) =>{
          accumulator += +currentValue?.amount
         return accumulator
        },
        0
      );
      console.log('ssx',sum)
      if(net*65/100<sum){
        return true
      }else{
        return false
      }
 }
  console.log("personal=>", normaliseWork(personalINformation));
  console.log("work->", normaliseWork(work));
  console.log("mortgage->", normaliseWork(mortgage));
  console.log('Obligations->',normaliseObligationData(obligations));
  function handleSubmit(event) {
    event.preventDefault();
    api
      .post(
        "leads/create-admin-lead",
        normalizeBody({
          personalInformation: normaliseWork(personalINformation),
          work: normaliseWork(work),
          obligationsData: normaliseObligationData(obligations),
          mortgage: normaliseWork(mortgage),
        })
      )
      .then((res) => navigat(`/lead/${res?.id}`))
      .catch(() => toast.error('error'))
  };
  return (
    <>
    <form onSubmit={handleSubmit} className="create-lead-container">
      <h1 className="header-text-lead">{!+id?t('Create Lead'):t('Uptade-Lead')}</h1>
      <div className="register-step">
  <h2>{t('Personal Information')}</h2>
  <FormControl variant="standard">
    <InputLabel shrink htmlFor="bootstrap-input">
      {t('Mobile')}
    </InputLabel>
    <BootstrapInput
      placeholder={t('Phone Number')}
      value={personalINformation?.mobileNumber}
      onChange={(e) =>
        setPersonalINformation({
          ...personalINformation,
          mobileNumber: e.target.value,
        })
      }
      id="bootstrap-input"
      required
    />
  </FormControl>
  <FormControl variant="standard">
    <InputLabel shrink htmlFor="bootstrap-input">
      {t('Full Name English')}
    </InputLabel>
    <BootstrapInput
      placeholder={t('Type Full Name')}
      value={personalINformation?.fullNameEng}
      onChange={(e) =>
        setPersonalINformation({
          ...personalINformation,
          fullNameEng: e.target.value,
        })
      }
      id="bootstrap-input"
      required
    />
  </FormControl>
  <FormControl variant="standard">
    <InputLabel shrink htmlFor="bootstrap-input">
      {t('Full Name Arabic')}
    </InputLabel>
    <BootstrapInput
      placeholder={t('Type Full Name')}
      value={personalINformation?.fullNameAr}
      onChange={(e) =>
        setPersonalINformation({
          ...personalINformation,
          fullNameAr: e.target.value,
        })
      }
      id="bootstrap-input"
      required
    />
  </FormControl>
  <div className="dov-container">
    <p>{t('DOB')}</p>
    <div className="dorp-down-container">
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          DD
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="DD"
          value={day}
          onChange={(e) => {
            setDay(e.target.value);
            if (day && months && year) {
              setPersonalINformation({
                ...personalINformation,
                dobHijri: dayFormat(day, mounth, year),
              });
            }
          }}
        >
          {days?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          MM
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="MM"
          size="small"
          value={mounth}
          onChange={(e) => {
            setMounth(e.target.value);
            if (day && months && year) {
              setPersonalINformation({
                ...personalINformation,
                dobHijri: dayFormat(day, mounth, year),
              });
            }
          }}
        >
          {(i18n.language=='en'?months:mounthAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          YYYY
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          size="small"
          id="demo-simple-select"
          label="YYYY"
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            if (day && months && year) {
              setPersonalINformation({
                ...personalINformation,
                dobHijri: dayFormat(day, mounth, year),
              });
            }
          }}
        >
          {years()?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="age-container">
        <span>{t('Age')}</span>
        {day && year && mounth ? (
          <span>{calculateAge(dayFormat(day, mounth, year))}</span>
        ) : null}
      </div>
    </div>
  </div>
  <div className="dov-container">
    <div className="dorp-down-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('Nationality')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Nationality')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Nationality"
            value={personalINformation?.nationality || ''}
            onChange={(e) =>
              setPersonalINformation({
                ...personalINformation,
                nationality: e.target.value,
              })
            }
          >
            {(i18n.language=='en'?nationalityListWithOptions:nationalityListWithOptionsArabic)?.map((i) => (
              <MenuItem value={i.label}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('Subsidized')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Subsidized')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Subsidized"
            value={personalINformation?.redf==null?'':personalINformation?.redf}
            onChange={(e) =>
              setPersonalINformation({
                ...personalINformation,
                redf: e.target.value,
              })
            }
          >
         <MenuItem value={true}>{t('yes')}</MenuItem>
            <MenuItem value={'false'}>{t('no')}</MenuItem>
            <MenuItem value={'falsee'}>{t('Not_Sure')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('First Home')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('First Home')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="First Home"
            value={personalINformation?.firstMortgage==null?'':personalINformation?.firstMortgage}
            onChange={(e) =>
              setPersonalINformation({
                ...personalINformation,
                firstMortgage: e.target.value,
              })
            }
          >
           <MenuItem value={true}>{t('yes')}</MenuItem>
            <MenuItem value={'false'}>{t('no')}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  </div>
  <hr />
</div>
<div className="register-step">
  <h2>{t('Work')}</h2>
  <div className="dov-container">
    <div className="dorp-down-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:'10px'
        }}
      >
        <p>{t('Work Sector')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Work Sector')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Work Sector"
            value={work?.workSector || ''}
            onChange={(e) =>
              setWork({ ...work, workSector: e.target.value })
            }
          >
            {workSector?.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {work?.workSector == 2 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap:"8px"
    
          }}
        >
          <p>{t('Rank')}</p>
          <FormControl fullWidth>
            <InputLabel size="small" id="demo-simple-select-label">
              {t('Rank')}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Rank"
              value={work?.militaryRank || ''}
              onChange={(e) =>
                setWork({ ...work, militaryRank: e.target.value })
              }
            >
              {MilitaryArray?.map((i) => (
                <MenuItem value={i.value}>{i.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}
      {work?.workSector == 2 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap:"8px"

          }}
        >
          <p>{t('Pilote')}</p>
          <FormControl fullWidth>
            <InputLabel size="small" id="demo-simple-select-label">
              {t('Pilote')}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Pilote"
              value={work?.isPilot || ''}
              onChange={(e) =>
                setWork({ ...work, isPilot: e.target.value })
              }
            >
              <MenuItem value={true}>{t('Yes')}</MenuItem>
              <MenuItem value={false}>{t('No')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : null}
    </div>
  </div>
  <div className="dov-container">
    <p>{t('Start of Service')}</p>
    <div className="dorp-down-container" style={{ maxWidth: "300px" }}>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          MM
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="MM"
          size="small"
          value={work?.jobStartDateHijri?.split("-")?.[1] || ''}
          onChange={(e) =>
            setWork({
              ...work,
              jobStartDateHijri: collectDate(
                e.target.value,
                work?.jobStartDateHijri?.split("-")?.[0]
              ),
            })
          }
        >
          {(i18n.language=='en'?months:mounthAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          YYYY
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          size="small"
          id="demo-simple-select"
          label="YYYY"
          value={work?.jobStartDateHijri?.split("-")?.[0] || ''}
          onChange={(e) =>
            setWork({
              ...work,
              jobStartDateHijri: collectDate(
                work?.jobStartDateHijri?.split("-")?.[1],
                e.target.value
              ),
            })
          }
        >
          {fullYear()?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  </div>
  <div className="dorp-down-container">
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Net Salary')}
      </InputLabel>
      <BootstrapInput
        placeholder={t('Type Net Salary')}
        value={work?.netSalary || ''}
        onChange={(e) => setWork({ ...work, netSalary: e.target.value })}
        id="bootstrap-input"
        type="number"
      />
    </FormControl>
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Basic Salary')}
      </InputLabel>
      <BootstrapInput
        placeholder={t('Type Basic Salary')}
        value={work?.basicSalary || ''}
        onChange={(e) => setWork({ ...work, basicSalary: e.target.value })}
        id="bootstrap-input"
        type="number"
      />
    </FormControl>
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Housing Allowance')}
      </InputLabel>
      <BootstrapInput
        placeholder={t('Type Housing Allowance')}
        value={work?.housingAllowance || ''}
        onChange={(e) =>
          setWork({ ...work, housingAllowance: e.target.value })
        }
        id="bootstrap-input"
        type="number"
      />
    </FormControl>
  </div>
  {salaryValidation(
    +work?.netSalary || 0,
    +work?.basicSalary || 0,
    +work?.housingAllowance || 0
  ) ? (
    <span style={{ color: "red" }}>
      {t(
        'Net salary can’t be less than sum of basic salary and allowances'
      )}
    </span>
  ) : null}
  <div className="dov-container">
    <p>{t('Salary Bank')}</p>
    <div className="dorp-down-container" style={{ maxWidth: "150px" }}>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          {t('Salary Bank')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Salary Bank"
          size="small"
          value={work?.salaryBank || ''}
          onChange={(e) =>
            setWork({ ...work, salaryBank: e.target.value })
          }
        >
          {(i18n.language=='en'?salaryOptions:salaryOptionsAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  </div>
  <hr />
</div>

<div className="register-step" style={{ maxWidth: "900px" }}>
  <h2>{t('Obligations')}</h2>
  <div className="check-container">
    <FormControlLabel
      value={debt}
      control={
        <Checkbox
          onChange={(e) => {
            setDebt(!debt);
            if (debt) {
              setObligationData({ defaultSimah: false });
            } else {
              setObligationData(null);
            }
          }}
        />
      }
      label={t('Beneficiary has no debt')}
      labelPlacement="end"
    />
    {!debt ? (
      <FormControlLabel
        value={simah}
        control={
          <Checkbox
            checked={simah == false}
            onChange={(e) => {
              setSimah(!simah);
              setObligationData({
                ...obligationsData,
                defaultSimah: simah,
              });
            }}
          />
        }
        label={t('Simah-Defaulted')}
        labelPlacement="end"
      />
    ) : null}
  </div>

  <div className="dov-container">
    {!debt
      ? obligations?.map((i) => (
          <div
            className="dorp-down-container"
            style={{ alignItems: "end", maxWidth: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "100px",
                gap:'10px'
              }}
            >
              <p className="label">{t('Type')}</p>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  {t('Type')}
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t('Nationality')}
                  value={i?.obligationType}
                  onChange={(e) =>
                    changeObligationData(
                      i.id,
                      "obligationType",
                      e.target.value
                    )
                  }
                >
                  {(i18n.language=='en'?obligationsEnum:loanTypesAb)?.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Total Amount')}
              </InputLabel>
              <BootstrapInput
                placeholder={t('Type Total Amount')}
                type="number"
                value={i?.loanTotalAmount}
                onChange={(e) =>
                  changeObligationData(i.id, "loanTotalAmount", e.target.value)
                }
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Monthly Installment')}
              </InputLabel>
              <BootstrapInput
                placeholder={t('Type Monthly Installment')}
                type="number"
                value={i?.amount}
                onChange={(e) =>
                  changeObligationData(i.id, "amount", e.target.value)
                }
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Tenure')}
              </InputLabel>
              <BootstrapInput
                placeholder={t('Type Tenure')}
                value={i?.loanDuration}
                onChange={(e) =>
                  changeObligationData(i.id, "loanDuration", e.target.value)
                }
                type="number"
                id="bootstrap-input"
              />
            </FormControl>
            <div className="dov-container" style={{ maxWidth: "300px" }}>
              <p className="label">{t('Start of Loan')}</p>
              <div
                className="dorp-down-container"
                style={{ maxWidth: "300px" }}
              >
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    MM
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="MM"
                    size="small"
                    value={i?.loanStartDateHijri?.split("-")?.[1]}
                    onChange={(e) =>
                      changeObligationData(
                        i.id,
                        "loanStartDateHijri",
                        collectDate(
                          e.target.value,
                          i?.loanStartDate?.split("-")?.[0]
                        )
                      )
                    }
                  >
                    {(i18n.language==='en'?months:mounthAb)?.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    YYYY
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    size="small"
                    id="demo-simple-select"
                    label="YYYY"
                    value={i?.loanStartDateHijri?.split("-")?.[0]}
                    onChange={(e) =>
                      changeObligationData(
                        i.id,
                        "loanStartDateHijri",
                        collectDate(
                          i?.loanStartDateHijri?.split("-")?.[1],
                          e.target.value
                        )
                      )
                    }
                  >
                    {fullYear()?.map((i) => (
                      <MenuItem value={i}>{i}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setObligations(obligations.filter((item) => item.id !== i.id))
              }
            />
          </div>
        ))
      : null}
  </div>
  {obligationsValidation(
    +work?.netSalary,
    obligations
  ) ? (
    <span style={{ color: "red" }}>
      {t('Sum of monthly installments can’t exceed 65% of salary in case mortgage available and 33% for personal loans')}
    </span>
  ) : null}
  <div className="Add-Button-container">
    {!debt ? (
      <PlusIcon
        color="secondary"
        style={{ cursor: "pointer" }}
        onClick={() =>
          setObligations([
            ...obligations,
            {
              id: new Date(),
              amount: "",
              loanTotalAmount: "",
              loanDuration: "",
              loanStartDateHijri: "",
              obligationType: "",
            },
          ])
        }
      />
    ) : null}
  </div>
  <hr style={{ width: "100%" }} />
</div>

<div className="register-step" style={{ maxWidth: "560px" }}>
  <h2>{t('Property')}</h2>
  <div className="check-container">
    <FormControlLabel
      value={true}
      control={
        <Checkbox
          onChange={(e) => {
            setIndentified(!indentified);
            setMorgage(null);
          }}
        />
      }
      label={t('Property not identified')}
      labelPlacement="end"
    />
  </div>
  {!indentified ? (
    <div className="container">
      <div className="dov-container">
        <div
          className="dorp-down-container"
          style={{ alignItems: "start", paddingBottom: "20px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap:'12px'
            }}
          >
            <p className="label">{t('Type')}</p>
            <FormControl fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                {t('Type')}
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('Nationality')}
                value={mortgage?.type || ''}
                onChange={(e) =>
                  setMorgage({
                    ...mortgage,
                    type: e.target.value,
                  })
                }
              >
                {simplifiedTypes?.map((i) => (
                  <MenuItem value={i.value}>{i.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              {t('Property price')}
            </InputLabel>
            <BootstrapInput
              placeholder={t('Type Amount')}
              value={mortgage?.propertyPrice || ''}
              onChange={(e) =>
                setMorgage({ ...mortgage, propertyPrice: e.target.value })
              }
              type="number"
              id="bootstrap-input"
            />
        {mortgage?.propertyPrice<100000?<span style={{ color: "red" ,fontSize:'12px',position:'absolute',bottom:'-40px'}}>
      {t('Property price can’t be less than SAR 100,000')}
    </span>:null}
          </FormControl>
    
        </div>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            {t('Downpayment available')}
          </InputLabel>
          <BootstrapInput
            placeholder={t('Type Downpayment')}
            value={mortgage?.downPayment || ''}
            onChange={(e) =>
              setMorgage({ ...mortgage, downPayment: e.target.value })
            }
            type="number"
            id="bootstrap-input"
          />
        </FormControl>
      </div>
      <p>
   
      </p>
    </div>
  ) : null}
  <div className="Add-Button-container">
    <Button variant="contained" type="submit" color="success">
      {!+id ? t('Create Lead') : t('Update Lead')}
    </Button>
  </div>
  <hr />
</div>
    </form>
    </>
  );
}

export default CreateLead;
