import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import * as Validator from "../../utils/validation";
import { AUTH_API_PATH } from "./apiPath";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    />
  );
}

export default function SignIn() {
  const [isValidPassword, setValidPassword] = useState(true);
  const [isValidConfirmPassword, setValidConfirmPassword] = useState(true);

  const password = useRef(null);
  const confirmPassword = useRef(null);

  const navigate = useNavigate();
  const { token } = useParams();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate("/cars");
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidPassword && isValidConfirmPassword) {
      axios
        .post(AUTH_API_PATH + "/reset-password", {
          password: password.current.value,
          confirmPassword: password.current.value,
          token: token || "",
        })
        .then(() => {
          navigate("/cars");
          toast.success("Success!");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  const checkValidPassword = ({ target }) =>
    setValidPassword(Validator.lengthMoreThan(target.value, 7));
  const checkValidConfirmPassword = () =>
    setValidConfirmPassword(
      password.current.value === confirmPassword.current.value
    );

  return (
    <>
      <div className="parentDiv">
        <img
          className="authIcon"
          src="https://static.ucraft.net/fs/ucraft/userFiles/munaaseb/images/1605-munaaseb-17010925088494.webp"
        ></img>
        <div className="childDiv">
          <Container component="main" maxWidth="xs">
            <Box className="parentForm">
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                className="forgote-password"
                  margin="normal"
                  inputRef={password}
                  required
                  fullWidth
                  id="pass"
                  label="New Password"
                  name="pass"
                  autoComplete="pass"
                  autoFocus
                  error={!isValidPassword}
                  onBlur={(event) => checkValidPassword(event)}
                />
                <TextField
                className="forgote-password"
                  margin="normal"
                  inputRef={confirmPassword}
                  required
                  fullWidth
                  name="confirm"
                  label="Confirm Password"
                  type="confirm"
                  id="confirm"
                  autoComplete="current-password"
                  error={!isValidConfirmPassword}
                  onBlur={checkValidConfirmPassword}
                />
                <Button
                  className="confirmButton"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Confirm
                </Button>
                <Link to="/forgot" text="Forgot" />
              </Box>
            </Box>
            <Copyright />
          </Container>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
