import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import InputLabel from '@mui/material/InputLabel';
import './SettingsUserModal.css';
import { useState } from "react";
import * as Validator from "../../utils/validation";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import api from './../../utils/api'
import Switch from '@mui/material/Switch';
import { isEditable } from '@testing-library/user-event/dist/utils';

export default function SettingsModal(props) {

    const [isValidNumber, setValidNumber] = useState(true)
    const [value, setValue] = useState('')
    const [password,setPassword] = useState('')
    const [name, setName] = useState(props.data?.name||'')
    const [email, setEmail] = useState(props.data?.email||'')
    const [role, setRole] = useState(props.data?.role||1)
    const [isValidUrl, setIsValidUrl] = useState(true)
   
    const checkValidUrl = (e) =>
    setIsValidUrl(Validator.isValidUrl(e.target.value));

    console.log(value)
    const createUser=()=>{
        api.post('/create-user', {
            name:name,
            email:email,
            password:password,
            role:role
        }).then(() => {
            toast.success("Succes!")
            props.fetchUser()
            props.setShowPopup(false)
        }).catch(() => {
            toast.error("Error!");
        })
    }
    const updateUser=()=>{
        api.put(`/${props?.data?.id}`, {
            name:name,
            email:email,
            role:role
        }).then(() => {
            toast.success("Succes!")
            props.fetchUser()
            props.setShowPopup(false)
        }).catch(() => {
            toast.error("Error!");
        })
    }
    

    return <Box className='overlead'>

        <Box className='form' component="form" onSubmit={props.submit}>

            <Button className='modalCloseButton' color='inherit' onClick={()=>props.setShowPopup(false)} variant="text">
                X
            </Button>
            <Typography variant='h5'>{!props.data?props.title:'Update User'}</Typography>
            {props.title == 'Edit Partner' ? <Box className='inputs'>
            <Box className='inputsParent'>
                <Box className='formControlChild'>
                <Select
                                size="small"
                                name='status'
                                defaultValue={props.settingRow.status}
                                variant="outlined"
                                placeholder='Status'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    minWidth: 200,
                                    borderRadius: '5px',
                                    width: '30%',
                                    margin: '0 0 0 0'
                                }}
                            >
                                <MenuItem value={1}>ACTIVE</MenuItem>
                                <MenuItem value={0}>INACTIVE</MenuItem>
                            </Select>
                </Box>
                <Box className='formControlChild'>
                <TextField
                                size="small"
                                error={!isValidUrl}
                                name='redirectUrl'
                                defaultValue={props.settingRow?.redirectUrl}
                                variant="outlined"
                                placeholder='Redirect URL'
                                label='Redirect URL'
                                margin="normal"
                                onBlur={(e) => checkValidUrl(e)}
                                sx={{
                                    m: 1,
                                    minWidth: 200,
                                    borderRadius: '5px',
                                    mt: '30px',
                                    width: '30%',
                                    margin: '0 0 0 0'
                                }}
                            >
                            </TextField>
                </Box>
            </Box>
            </Box>
:
            <Box className='inputs'>
            <Box className='inputsParent'>
                <Box className='formControlChild'>
                <TextField
                                size="small"
                                error={!isValidUrl}
                                name='name'
                                defaultValue={props?.data?.name}
                                onChange={(e)=>setName(e.target.value)}
                                variant="outlined"
                                placeholder='Name'
                                label='Name'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    minWidth: 200,
                                    borderRadius: '5px',
                                    width: '30%',
                                    margin: '0 0 0 0',
                                    mt: '30px'
                                }}
                            >
                            </TextField>
                </Box>
                <Box className='formControlChild'>
                <TextField
                                size="small"
                                name='email'
                                variant="outlined"
                                type='email'
                                onChange={(e)=>setEmail(e.target.value)}
                                defaultValue={props?.data?.email}
                                placeholder='Email'
                                label='email'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    minWidth: 200,
                                    borderRadius: '5px',
                                    width: '30%',
                                    margin: '0 40 0 0'
                                }}
                            ></TextField>
                </Box>
            <Box className='phone-label'>
                <PhoneInput
                    placeholder="Enter phone number"
                     value={value}
                    onChange={setValue}
                    color={
                             'primary'
                            
                    }
                    />
               </Box>
               {!props.data?<Box className='formControlChild'>
                <TextField
                                size="small"
                                name='password'
                                variant="outlined"
                                type='password'
                                onChange={(e)=>setPassword(e.target.value)}
                                placeholder='password'
                                label='password'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    minWidth: 200,
                                    borderRadius: '5px',
                                    width: '30%',
                                    margin: '0 40 0 0'
                                }}
                            ></TextField>
                </Box>:null}
                <Box className='formControlChild'>
                <Select
                                size="small"
                                name='status'
                                variant="outlined"
                                placeholder='Status'
                                defaultValue={props?.data?.role||1}
                                onChange={(e)=>setRole(e.target.value)}
                                margin="normal"
                                sx={{
                                    m: 1,
                                    minWidth: 200,
                                    borderRadius: '5px',
                                    width: '30%',
                                    margin: '0 0 0 0'
                                }}
                            >
                                <MenuItem value={1}>ADMIN</MenuItem>
                                <MenuItem value={2}>SALES HEAD</MenuItem>
                                <MenuItem value={3}>SALES</MenuItem>
                            </Select>
                </Box>
            </Box>
         
            </Box>
            
            }
            <Box className='tempsParent'>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button color='inherit' 
                onClick={()=>(!props.data?createUser():updateUser())}
                    sx={{ width: '30%', margin: '10px' }} variant='outlined'
                >{!props.data?props.button:'Update'}</Button>
            </Box>
        </Box>
    </Box>
}