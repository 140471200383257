export const translatioanAb = {
    navText1:'زبائن مرشحة',
    navText2:'المستخدمين ',
    navText3:'الأجزاء',
    navText4:'آلة حاسبة',
    logOut:'تسجيل الخروج',
    logoutText:'هل أنت متأكد من أنك تريد الخروج من هذه الصفحة؟',
    yes:'نعم',
    no:"لا",
    firstTableCell1:'معرف الهوية ',
    firstTableCell2:'الاسم الأول باللغة الإنجليزية',
    firstTableCell3:'رقم الهاتف',
    firstTableCell4:'الاسم الأول باللغة العربية',
    firstTableCell5:'البريد الالكتروني',
    firstTableCell6:'الاسم ',
    firstTableTabs1:'معلومات شخصية ',
    firstTableTabs2:'العمل ',
    firstTableTabs3:'الإلتزامات ',
    firstTableTabs4:'المعلنين  ',
    firstTableTabs5:'توصية  ',
    firstTableCell6:'مصدر ',
    status:'الحالة الحالية ',
    datePlaceHolder: 'تاريخ استحقاق الإجراء ',
    comment:'تعليق ',
    assignee:'المعين للعمل ' ,
    limt:'حد ',
    limits:'حدود ',
    logs:'استجابة للطلب ',
    save:'حفظ',
    "Create Lead": "إنشاء فرصة",
    "Personal Information": "معلومات شخصية",
    "Simah-Defaulted":'تخلفت سيما',
    "Mobile": "هاتف",
    "Phone Number": "رقم الهاتف",
    "Full Name English": "الاسم الكامل باللغة الانجليزية",
    "Type Full Name": "اكتب الاسم الكامل",
    "Full Name Arabic": "الاسم الكامل باللغة العربية",
    "DOB": "تاريخ الميلاد",
    "DD": "يوم",
    "MM": "شهر",
    "YYYY": "سنة",
    "Age": "العمر",
    "Nationality": "الجنسية",
    "Subsidized": "مدعوم",
    "First Home": "المنزل الأول",
    "Work": "العمل",
    "Work Sector": "قطاع العمل",
    "Start of Service": "بداية الخدمة",
    "Net Salary": "الراتب الصافي",
    "Type Net Salary": "اكتب الراتب الصافي",
    "Basic Salary": "الراتب الأساسي",
    "Type Basic Salary": "اكتب الراتب الأساسي",
    "Housing Allowance": "بدل السكن",
    "Type Housing Allowance": "اكتب بدل السكن",
    "Salary Bank": "البنك الذي يصرف راتبك فيه",
    "Obligations": "التزامات",
    "Beneficiary has no debt": "المستفيد ليس لديه ديون",
    "Simah Defaulted": "Simah Defaulted",
    "Type": "نوع",
    "Personal": "شخصي",
    "Uptade-Lead":'تحديث العميل المحتمل',
    "Car": "سيارة",
    Not_Sure :' غير متأكد',
    "Mortgage": "رهن عقاري",
    "Credit Card": "بطاقة ائتمان",
    "Emkan": "إمكان",
    "Total": "الإجمالي",
    "Total Amount": "المبلغ الإجمالي",
    "Type Total Amount": "اكتب المبلغ الإجمالي",
    "Monthly Installment": "القسط الشهري",
    "Type Monthly Installment": "اكتب القسط الشهري",
    "Tenure": "مدة السداد",
    "Type Tenure": "اكتب مدة السداد",
    "Start of Loan": "بداية القرض",
    "Property": "عقار",
    "Property not identified": "لم يتم تحديد العقار",
    "Apartment": "شقة",
    "Land": "أرض",
    "Villa": "فيلا",
    "Floor": "طابق",
    "Other": "آخر",
    "Downpayment available": "الدفعة الأولى متاحة",
    "Type Downpaymant": "اكتب الدفعة الأولى",
    "SAR 150,000 down payment": "بناءً على المدخلات، يحتاج المستخدم إلى 150,000 ريال سعودي كدفعة أولى",
    "Leads": "فرص",
    "Settings": "الإعدادات",
    "Calculator": "الآلة الحاسبة",
    "Users": "المستخدمين",
    "Calculations": "الحسابات",
    "Retirement Age": "سن التقاعد",
    "Year To retire": "السنة المتبقية حتى التقاعد",
    "Salary": "الراتب",
    "Salary After Retirement": "الراتب بعد التقاعد",
    "Total Obligations": "إجمالي الالتزامات",
    "Obligations available to refinance": "الالتزامات المتاحة لإعادة التمويل",
    "DBR": "نسبة الدين إلى العبء",
    "Solutions": "حلول",
    "Sell him mortgage only": "بيع له التمويل العقاري فقط",
    "Refinance the previous personal loan and raise the deduction to 33%...": "تمويل القرض الشخصي السابق وزيادة الخصم إلى ٣٣٪...",
    "Max Loan Amount": "أقصى مبلغ القرض",
    "Bank": "البنك",
    "Benefit": "فائدة",
    "Tenure": "فترة السداد",
    "Installment": "القسط",
    "APR": "النسبة السنوية للفائدة",
    "Max Loan Amount without liabilities": "الحد الأقصى لمبلغ القرض بدون التزامات",
    "Monthly subsidy": "الدعم الشهري",
    "Subsidy package": "حزمة الدعم",
    "Property price": "سعر العقار",
    "DP Needed": "الدفعة الأولى مطلوب",
    "DP Available": "الدفعة الأولى متاحة",
    "DP missing": "لا توجد دفعة اولى",
    "Extra Expenses": "المصاريف الإضافية",
    "Personal Information": "المعلومات الشخصية",
    "Work": "العمل",
    "Obligations": "الالتزامات",
    "Advertisers": "المعلنين",
    "Recommendation": "توصية",
    "Attach File": "إرفاق ملف",
    "Created At": "تم الإنشاء في",
    "Updated At": "تم التحديث في",
    "Status": "الحالة",
    "Assignee": "المسؤول",
    "Change": "تغيير",
    "VAT_5": "ضريبة القيمة المضافة 5% = ",
    "broker_fee_2.5": "رسوم الوسيط 2.5% = ",
    "bank_processing_fee": "رسوم معالجة البنك = "
};
