import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { json, useParams } from 'react-router-dom';
import api from "../../utils/api";
import Button from '@mui/material/Button';
import { correctDate, correctDateWithoutMinutes } from '../../utils/date';
import Editor from '@monaco-editor/react';
import Card from '@mui/material/Card';
import Upload from '../Import/ImportUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import DeletePopup from '../../parts/Menu/DeletPopUp';
import { toast } from 'react-toastify';    
import Tabs from '@mui/material/Tabs';
import bank1 from './../../image/bank1.png'
import bank2 from './../../image/bank2.png'
import bank3 from './../../image/bank3.png'
import bank4 from './../../image/bank4.png'
import bank5 from './../../image/bank5.png'
import bank6 from './../../image/bank6.png'
import bank7 from './../../image/bank7.png'
import bank8 from './../../image/bank8.png'
import bank9 from './../../image/bank9.png'
import bank10 from './../../image/bank10.png'
import bank11 from './../../image/bank11.png'
import bank12 from './../../image/bank12.png'
import bank13 from './../../image/bank13.png'
import { DatePicker } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import img from './../../../src/image/Riyadh.png'
import Obligations from './Obligations';
import uq from '@umalqura/core';
import {CardActionArea} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useStateManager } from 'react-select';
import Input from '../../Components/Input';



export default function Setting() {
  const navigat = useNavigate()
  const role = JSON.parse(localStorage.getItem('user'))?.role
  const accountName = JSON.parse(localStorage.getItem('user'))?.name
  const personalId = JSON.parse(localStorage.getItem('user'))?.id
  const months = [
    { value: 1, label: "al-Muḥarram" },
    { value: 2, label: "Ṣafar" },
    { value: 3, label: "Rabīʿ al-ʾAwwal" },
    { value: 4, label: "Rabīʿ ath-Thānī" },
    { value: 5, label: "Jumādā al-ʾŪlā" },
    { value: 6, label: "Jumādā ath-Thāniyah" },
    { value: 7, label: "Rajab" },
    { value: 8, label: "Shaʿbān" },
    { value: 9, label: "Ramaḍān" },
    { value: 10, label: "Shawwāl" },
    { value: 11, label: "Ḏū al-Qaʿdah" },
    { value: 12, label: "Ḏū al-Ḥijjah" },
  ];
  const  mounthAb = [
    { value: 1, label: "محرم" },
    { value: 2, label: "صفر" },
    { value: 3, label: "ربيع الأول" },
    { value: 4, label: "ربيع الثاني" },
    { value: 5, label: "جمادى الأولى" },
    { value: 6, label: "جمادى الثانية" },
    { value: 7, label: "رجب" },
    { value: 8, label: "شعبان" },
    { value: 9, label: "رمضان" },
    { value: 10, label: "شوال" },
    { value: 11, label: "ذو القعدة" },
    { value: 12, label: "ذو الحجة" },
  ]
  const fullYear = () => {
    let arr = [];
    for (let i = 1318; i <= uq(new Date())._hy; i++) {
      arr.push(i);
    }
    return arr.sort((a, b) => b - a);
  };
  const {t,i18n} = useTranslation()
  const banks = {
    Rajhi: bank1,
    SNB: bank2,
    INMA: bank3,
    Bilad: bank4,
    Riyadh: img,
    Fransi: bank5,
    Emirates: bank6,
    ANB: bank7,
    SAB: bank8,
    Al_Jazirah: bank9,
    Dutch_Gulf_MFC: bank10,
    SHL_MFC: bank11,
    Dar_al_Tamleek_MFC: bank12,
    Bidaya_MFC: bank13
  }
  const workSector = {
    1: "Government",
    2: "Military",
    3:"Private (SME)" ,
    4:"Private (Enterprise)",
    5:"Retired"
  }
  const documentType = {
    1:'NID',
    2:'IQAM'
  }
  const menuItems = {
    1: 'No response',
    2: 'Attach the papers',
    3: 'Yes to contact',
    4: 'Ten',
    5: 'Needs follow up',
    6: 'Looking for a property',
    7: 'Waiting for papers',
    8: 'He does not want/ Not interested',
    9: 'What does the financing cover?',
    10: 'Poor salary',
    11: 'Financing is not possible',
    12: 'Take financing',
    13: 'Unable to connect',
    14: 'The offer does not suit him',
    15: 'Unresponsive',
    16: 'Property Owner',
    17: 'Real Estate Marketer'
  };
  const workSubSector = {
    1:"Goverment",
    2:"Miltary",
    3:"Private",
    4:"Retired"
  }
  const [age, setAge] = React.useState('');

  function calculateAge(birthDate) {

    const currentDate = new Date();
    const dob = new Date(birthDate);

    let age = currentDate.getFullYear() - dob.getFullYear();

    const currentMonth = currentDate.getMonth();
    const birthMonth = dob.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < dob.getDate())
    ) {
      age = age - 1;
    }

    return age;
  }
  const  styles = { width: '100%', display:'flex', };

  const Miltary = {
    "1": "Lieutenant",
    "2": "First lieutenant",
    "3": "Captain",
    "4": "Major",
    "5": "Lieutenant colonel",
    "6": "Colonel",
    "7": "Brigadier",
    "8": "General",
    "9": "Marshal",
    "10": "First Marshal",
    "11": "Private",
    "12": "First Private",
    "13": "Corporal",
    "14": "Second Sergeant",
    "15": "Sergeant",
    "16": "First Sergeant",
    "17": "Staff sergeant"
  }
  const obligationsEnum = {
    1:'Personal',
    2:'Car',
    3:'Mortgage',
    4:'Credit card',
    5:'Emkan',
    6:'Total'
  }
    const [info,setInfo] = useState(null)
    console.log('sx',info?.lastetHistory)
    const [users,setUsers] = useState([])
    const [key,setKey] = useState(0)
    const [userId,setUserId] = useState('')
    const [showDeletePopup,setShowDeletePopup] = useState(false)
    const [status,setSttaus] = useState(info?.lastetHistory?.status||'')
    const [nextActionDate,setnextActionDate] = useState()
    const [comment,setcmment] = useState('')
    const [response,setResponse] = useState({})
    const [user, setUser] = useState([])
    const [actionBar,setActionBar] = useState(false)
    const [limit,setLimit] = useState(null)
    const [advertisers,setAdvertisers]= useState([])
    const [logs,setLogs] = useState([])
    const [scores,setScores] = useState([])
    const [work,setWork] = useState([])
    const [value2,setValue2] = useState(0)
    const [rejected,setRejected] = useState(false)
    const [value, setValue] = React.useState(0);
    const [obiligations,setObligations]= useState([])
    const [minReq,setMinReq] = useState(null)
    const [scoresDms,setScoresDms] = useState(null)
    
  const handleChangeStatus = (event) => {
    setSttaus(event.target.value);
  };
  const obligationsEnums = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Car" },
    { value: 3, label: "Mortgage" },
    { value: 4, label: "Credit card" },
    { value: 5, label: "Emkan" },
    { value: 6, label: "Total" },
  ];
  const simplifiedTypes = [
    { value: 1, name: 'Apartment' },
    { value: 2, name: 'Land' },
    { value: 3, name: 'Villa' },
    { value: 4, name: 'Floor' },
    { value: 5, name: 'Other' }
  ];
  console.log('min',minReq)
  const handleChangeUser = (event) =>{
    setUserId(event.target.value)
  }
    const mapingData = {
      personal:user,
      works:work,
      advertisers:advertisers,
      obiligation:obiligations,
      scores:scores,
    } 
    const subMappingData={
      logs:logs,
      limit:limit
    }
    const booleanEnums = {
        true:'Yes',
        false:'No'
    }
    const { id} = useParams()
    const [selectedRows, setSelectedRows] = useState({});
    const [name,setName] = useState('Personal Information')
    const [subKey,setSubKey] = useState('')
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      'label + &': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        marginRight:theme.spacing(2)
      },
      '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }));
    console.log(scoresDms)
    // console.log(scoresDms?.response?.Limit ?Object.entries(Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.morthgageGraphic).sort((a,b)=>b[0].split('-')?.[1]-a[0].split('-')?.[1])?.[0]?.split('-')[1]:null)
    const updateStatus = (id,userId,status,nextActionDate,comment) =>{
      api.post(`/leads/create-lead-history/${id}`,{
                    userId,
                    status,
                    nextActionDate,
                    comment
      }).then((e)=>{
        RenderData()
        toast.success('History Updated')
   
      }).catch((e)=>toast.error(e.message))
    }

    function a11yProps(index) {
      return {
        id:`simple-tab-${index}`,

      };
    }
    console.log(scoresDms?.response?.Limit ?Object.entries(Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.morthgageGraphic):[])
   const showAcctionBar = () =>{
    setValue(actionBar?value:0)
    setActionBar(!actionBar)
   }
   const handleEditorDidMount =(e) => {
    // editorRef.current = e;
   setTimeout(()=>{
    e.getAction('editor.action.formatDocument').run()
   },100)
  }
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-US', { useGrouping: true })
  }
    const handleChange = (event,newValue) => {
  
      setValue(newValue);
    };
    const handleChange2 = (event,newValue) => {
      setValue2(newValue);
    };
    useEffect(()=>{
      api.get('/users').then(e=>setUsers(e))
    },[])
    function CustomTabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          className='panel'
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{p:3}}>
              {children}
            </Box>
          )}
        </div>
      );
    }
    function CustomTabPanel1(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          className='custtom-panel'
          style={value !== index?{padding:0}:{}}
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{width:'100%'}}>
              {children}
            </Box>
          )}
        </div>
      );
    }
    function isValidISODate(dateString) {
      const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      return isoRegex.test(dateString);
    }
    function CustomTabPanel2(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ display:'flex',flexWrap:'wrap'}}>
              {children}
            </Box>
          )}
        </div>
      );
    }
    const normalizeUser = (_user) => {
      return {
        lastetHistory: _user?.statusHistories?.sort((a,b)=> b.id-a.id)?.[0],
        work:_user.works?.length?Object.entries(..._user.works.map(i=>{
          return {...i,workSector:workSector[i?.workSector],workSubSector:workSubSector[i?.workSubSector],militaryRank:Miltary[i?.militaryRank]}
        })):[],
        obiligations:_user?.obligations?.length?_user?.obligations.map(i=>{
          return{...i,obligationType:obligationsEnum[i.obligationType]}
        }):[],
        advertisers:_user?.advertisers?.length?Object.entries(_user?.advertisers[0]):[],
        scores:_user?.scores.length?_user?.scores.sort((a,b)=>b.id-a.id):[],
        attachments: _user?.attachments.length?_user.attachments:[],
        lastetScores:_user?.scores.length?JSON.parse(_user?.scores?.sort((a,b)=>b.id-a.id)?.[0].response)?.response:{},
      }
    }
    console.log(window.innerWidth)
    const RenderData = () =>{
      api.get(`/leads/${id}`).then((_user) => {
        setResponse(_user)
        setScoresDms(_user?.scores.length?JSON.parse(_user?.scores?.sort((a,b)=>b.id-a.id)?.[0]?.response):null)
          setUser( Object.entries(_user));
      setInfo(normalizeUser(_user))
      setSttaus(_user?.statusHistories?.sort((a,b)=> b.id-a.id)?.[0]?.status)
      setUserId(_user?.statusHistories?.sort((a,b)=> b.id-a.id)?.[0]?.user?.id)
      setnextActionDate(new Date(_user?.statusHistories?.sort((a,b)=> b.id-a.id)?.[0]?.nextActionDate))
      setcmment(_user?.statusHistories?.sort((a,b)=> b.id-a.id)?.[0]?.comment)

      }).catch(console.log)
    }

    const DeleteItem = (id) => {
      api.delete(`leads/attachment/${id}`).then(e=>{
        setShowDeletePopup(false)
        RenderData()
      }).catch(e=>console.error(e))
    }
    useEffect(() => {
      RenderData()
    
    }, [id])
    console.log(obiligations)
     console.log('ss',scoresDms)
    return<div className='mainContainer'>
 {/* {response?.statusHistories?.sort((a,b)=>b.id-a.id)[0].user.name} */}
     <Box className='info'>

      <div className='infoConatiner' style={{justifyContent:'space-between'}}>
      <Button sx={{width:'150px', height:'28px',marginRight:'16px',marginLeft:'16px',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
           background:'var(--Gray-100, #F2F4F7)'
      },}}  size='small' onClick={()=>navigat(`/create-lead/${id}`)} variant="contained">{t('Update Lead')}</Button>
        <div style={{display:'flex'}}>    
        <div className='infoItem'>
          <p>CreateAt</p>
          <span>{correctDate(response?.statusHistories?.sort((a,b)=>b.id-a.id)[0]?.createdAt)} </span>
        </div>
        <div className='infoItem'>
          <p>UpdateAt</p>
          <span>{correctDate(response?.statusHistories?.sort((a,b)=>b.id-a.id)[0]?.updateAt)} </span>
        </div>
        <div className='infoItem'>
          <p>Status</p>
          <span>{menuItems[response?.statusHistories?.sort((a,b)=>b.id-a.id)[0]?.status]} </span>
        </div>
      <div className='infoItem'>
          <p>Assignee</p>
          <span>{response?.statusHistories?.sort((a,b)=>b.id-a.id)[0]?.user.name} </span>
        </div>
      
        <Button  sx={{width:'100px', height:'28px',marginRight:'16px',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
           background:'var(--Gray-100, #F2F4F7)'
      }}} size='small' onClick={()=>showAcctionBar()} variant="contained">{t('Change')}</Button>
        </div></div>
        <div className='app-bar-container'>
        <AppBar sx={{background:'#eff1f5'}} position="static">
       <Tabs    
        value={value}
        onChange={handleChange}
        sx={{width:'100%'}}
        scrollButtons="auto" >
            <Tab   sx={{width:`${100/7}%`}} label={t('OverView')}  onClick={()=>{setName('Personal Information')
          setKey('personal')
      }}  {...a11yProps(0)}/>
          <Tab   sx={{width:`${100/7}%`}} label={t('firstTableTabs1')}  onClick={()=>{setName('Personal Information')
          setKey('personal')
      }}  {...a11yProps(1)}/>
          <Tab   sx={{width:`${100/7}%`}} label={t('firstTableTabs2')} onClick={()=>{setName('Work Information')
          setKey("works")
      }} {...a11yProps(2)} />
          <Tab    sx={{width:`${100/7}%`}}label={t('firstTableTabs3')}   onClick={()=>{setName('Obiligations Information')
            setKey('obiligation')}} {...a11yProps(3)}/>
          <Tab   sx={{width:`${100/7}%`}} label={t('firstTableTabs4')}  onClick={()=>{setName('Advertisers')
            setKey('advertisers')}} {...a11yProps(4)}/>
          <Tab    sx={{width:`${100/7}%`}}label={t('firstTableTabs5')}  onClick={()=>{setName('Scores')
            setKey('scores')}}  {...a11yProps(5)} />
             <Tab   sx={{width:`${100/7}%`}} label={t('Attach File')}  onClick={()=>{setName('Attach File')
            setKey('scores')}}  {...a11yProps(6)} />
        </Tabs>
        </AppBar>
        </div>
     <CustomTabPanel1 value={value} index={0}>
     <p>{t('Calculations')}</p>
        <hr/>
       {actionBar?<div className='actionspopup' >
          <div className='input-Container'>
        <FormControl className={i18n.language=='ab'?'arabic':''} sx={{width:'100%'}}>
        <InputLabel id="demo-simple-select-label">{t('assignee')}</InputLabel>
         <Select
              sx={{height:'100%'}}
            className='arabic'
            value={role==3?personalId:userId}
            label="Staus"
            onChange={handleChangeUser}
        >
          {role!==3?users.map((i,index)=><MenuItem key={index} value={i?.id}>{i?.name}</MenuItem>):<MenuItem value={personalId}>{accountName}</MenuItem>}
          
     
        </Select>  
      </FormControl>
                 <DatePicker
            size="lg"  style={styles}
            typeof='hrriji'
            format="yyyy-MM-dd"
            value={nextActionDate=='Invalid Date'?null:nextActionDate}
            onChange={(newValue) => {
              console.log('sxx',newValue)
              if(newValue!=='Invalid Date'){
                  setnextActionDate(newValue)
              }
              }}
                      />
      <FormControl    className={i18n.language=='ab'?'arabic':''}  sx={{width:'100%'}} >
        <InputLabel id="demo-simple-select-label">{t('status')}</InputLabel>
        <Select
            sx={{height:'100%'}}
          value={status}
          label={t('Status')} 
          onChange={(e)=>setSttaus(e.target.value)}
        >
          <MenuItem value={1}>No response</MenuItem>
          <MenuItem value={2}>Attach the papers</MenuItem>
          <MenuItem value={3}>Yes to contact</MenuItem>
          <MenuItem value={4}>Ten</MenuItem>
          <MenuItem value={5}>Needs follow up</MenuItem>
          <MenuItem value={6}>Looking for a property </MenuItem>
          <MenuItem value={7}>Waiting for papers</MenuItem>
          <MenuItem value={8}>He does not want/ Not interested</MenuItem>
          <MenuItem value={9}>What does the financing cover?</MenuItem>
          <MenuItem value={10}>Poor salary</MenuItem>
          <MenuItem value={11}>Financing is not possible</MenuItem>
          <MenuItem value={12}>Take financing</MenuItem>
          <MenuItem value={13}>Unable to connect</MenuItem>
          <MenuItem value={14}>The offer does not suits him</MenuItem>
          <MenuItem value={15}>Unresponsive</MenuItem>
          <MenuItem value={16}>Property Owner</MenuItem>
          <MenuItem value={17}>Real Estate Marketer</MenuItem>
        </Select>
      </FormControl>
          </div>
          <textarea onBlur={(e)=>setcmment(e.target.value)} placeholder={t('comment')} defaultValue={comment} className='textArea' rows={8}/>
          <div  className='button-container'><Button sx={{width:'200px',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
           background:'var(--Gray-100, #F2F4F7)'
      }}} onClick={()=>updateStatus(id,userId,status,nextActionDate,comment)} disabled={!userId||!comment||!nextActionDate||!status} variant="contained">{t('save')}</Button></div>
        </div>:null}
           <div className='lead-info'>
            <p>{response?.mobileNumber}</p>
            <p>{response?.fullNameEng}</p>
      <div className='row-data'>
        <div>
          <p>{t('Age')}</p>
          <p>{calculateAge(response?.dobGreg)}</p>
        </div>
        <div>
          <p>{t('Retirement Age')}</p>
          <p>{scoresDms?.response?.RetirementAge||'---'}</p>
        </div>
        <div>
          <p>{t('Year To retire')}</p>
          <p>{scoresDms?.response?.RetirementAge - calculateAge(response?.dobGreg) > 0 ? scoresDms?.response?.RetirementAge - calculateAge(response?.dobGreg) : '----'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Salary')}</p>
          <p>{formatNumberWithCommas(+response?.works?.[0]?.netSalary)||'----'}</p>
        </div>
        <div>
          <p>{t('Salary After Retirement')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.salaryAfterRetierement)||formatNumberWithCommas(+response?.works?.[0]?.basicSalary)||'----'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Total Obligations')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.totalObligations)||'----'}</p>
        </div>
        <div>
          <p>{t('Obligations available to refinance')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.obligationsToRefinance)||'----'}</p>
        </div>
        <div>
          <p>{t('DBR')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.userDBR)||0}%</p>
        </div>
      </div>
          
      {response?.obligations?.map((obligation, index) => (
        <div className="dorp-down-container" style={{ maxWidth: '100%' }} key={index}>
          <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100px',gap:'10px'}}>
            <p className="label" style={{ marginTop: '0' }}>{t('Type')}</p>
            <FormControl fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                {t('Type')}
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('Type')}
                value={obligation?.obligationType}
                disabled
              >
                {(obligationsEnums)?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>{option?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              {t('Total Amount')}
            </InputLabel>
            <BootstrapInput
              placeholder={t('Type Total Ammount')}
              type="number"
              value={+obligation?.loanTotalAmount || ''}
              disabled
              id="bootstrap-input"
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              {t('Monthly Installment')}
            </InputLabel>
            <BootstrapInput
              placeholder={t('Type Mounthly installment')}
              type="number"
              value={+obligation?.amount || ''}
              id="bootstrap-input"
              disabled
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              {t('Tenure')}
            </InputLabel>
            <BootstrapInput
              placeholder={t('Type Tenure')}
              value={obligation?.loanDuration}
              disabled
              type="number"
              id="bootstrap-input"
            />
          </FormControl>
          <div className="dov-container">
            <p className="label">{t('Start of Loan')}</p>
            <div className="dorp-down-container" style={{ maxWidth: '300px' }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  MM
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="MM"
                  size="small"
                  disabled
                  value={obligation?.loanStartDateHijri?.split('-')?.[1]}
                >
                  {months?.map((month, index) => (
                    <MenuItem key={index} value={month.value}>{month.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  YYYY
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  size="small"
                  id="demo-simple-select"
                  disabled
                  label="YYYY"
                  value={obligation?.loanStartDateHijri?.split('-')?.[0]}
                >
                  {fullYear()?.map((year, index) => (
                    <MenuItem key={index} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="row-data" style={{ gap: 0 }}>
                <div>
                  <p>{scoresDms?.response?.paidTotal?.find(i => i.index === index)?.paid}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}   
            
            <p>
        {t('Solutions')} ({scoresDms?.response?.Solutions?.length})
      </p>
      {scoresDms?.response?.Solutions?.length?scoresDms?.response?.Solutions?.map((solution, index) => (
        <div className='solutions' key={index}>
          <p>{solution?.key}</p>
          <span>{solution?.descEn}</span>
          <span>{solution?.descAr}</span>
        </div>
      )):<span>No Solutions available</span>}
      <div className='row-data'>
        <div>
          <p>{t('Max Loan Amount')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.Limit ? Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limit : null)||'----'}</p>
        </div>
        <div>
          <p>{t('Bank')}</p>
          <p>{scoresDms?.response?.Limit ? Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.bank : '----'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Tenure')}</p>
          <p>{scoresDms?.response?.Limit ? Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.Tenure/12 : 0} Years</p>
        </div>
       
        <div>
          <p>{t('APR')}</p>
          <p>{scoresDms?.response?.Limit ? Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.rate:0} %</p>
        </div>
      </div>
      <div className='row-data'> 
      {scoresDms?.response?.Limit ?Object.entries(Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.morthgageGraphic)?.map(i=> 
         <div>
          <p>{t('Installment')} ({i?.[0]})</p>
          <p>{formatNumberWithCommas(i?.[1])||'----'}</p>
        </div>):null}
    
        </div>
      <div className='row-data'>
        <div>
          <p>{t('Max Loan Amount without liabilities')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.Limit ? Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limitWithoutObligations : '----')}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Monthly subsidy')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.subsidy)||'----'}</p>
        </div>
        <div>
          <p>{t('Subsidy package')}</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.packageAmount)||'----'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Property price')}</p>
          <p>{formatNumberWithCommas(+response?.mortgages?.[0]?.propertyPrice)||'----'}</p>
        </div>
        <div>
          <p>{t('DP Needed')}</p>
          <p>{formatNumberWithCommas(+response?.mortgages?.[0]?.propertyPrice * 0.1)||'----'}</p>
        </div>
        <div>
          <p>{t('DP Available')}</p>
          <p>{formatNumberWithCommas(+response?.mortgages?.[0]?.downPayment)||'----'}</p>
        </div>
        <div>
          <p>{t('DP missing')}</p>
          <p>{formatNumberWithCommas(+response?.mortgages?.[0]?.propertyPrice * 0.1 - +response?.mortgages?.[0]?.downPayment)||'----'}</p>
        </div>
        <div>
          <p>{t('Property price')} (10% DP)</p>
          <p>{formatNumberWithCommas(scoresDms?.response?.Limit ? Object.values(scoresDms?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.propertyPrice10DP : null)||'----'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('Extra Expenses')}</p>
          <p>SAR {formatNumberWithCommas(scoresDms?.response?.BankFee + scoresDms?.response?.BrokerCheck + scoresDms?.response?.Vat)||'----'}</p>
        </div>
      </div>
      <div className='row-data'>
        <div>
          <p>{t('VAT_5')}{scoresDms?.response?.Vat}</p>
          <p>{t('broker_fee_2.5')}{formatNumberWithCommas(scoresDms?.response?.BrokerCheck)||'----'}</p>
          <p>{t('bank_processing_fee')}{formatNumberWithCommas(scoresDms?.response?.BankFee)||'----'}</p>
        </div>
      </div>
           </div>
      </CustomTabPanel1>
      <CustomTabPanel1 value={value} index={1}>
        <p>{t('firstTableTabs1')}</p>
        <hr/>
        <div className='info-container'>
         <Input name={"Id"} value={response?.id} disable={true}/>
         <Input name={"Full Name Eng"} value={response?.fullNameEng||'null'} disable={true}/>
         <Input name={"Full Name Ar"} value={response?.fullNameAr||'null'} disable={true}/>
         <Input name={"Mobile Number"} value={response?.mobileNumber||'null'} disable={true}/>
         <Input name={"Email"} value={response?.email||'null'} disable={true}/>
         <Input name={t('DOB Gerg')} value={correctDateWithoutMinutes(response?.dobGreg)} disable={true}/>
         <Input name={t('DOB Hijri')} value={correctDateWithoutMinutes(response?.dobHijri)} disable={true}/>
         <Input name={t('Retirement Age')} value={response?.retirementAge||'null'} disable={true}/>
         <Input name={t('Document Type')} value={documentType[response?.documentType]||'null'} disable={true}/>
         <Input name={t('Nationality')} value={response?.nationality||'null'} disable={true}/>
         <Input name={t('Document Number')} value={response?.documentNumber||'null'} disable={true}/>
         <Input name={t('Document Expire Date Greg')} value={correctDateWithoutMinutes(response?.documentExpireDateGreg)||'null'} disable={true}/>
         <Input name={t('Document Expire Date Hijri')} value={correctDateWithoutMinutes(response?.documentExpireDateHijri)||'null'} disable={true}/>
         <Input name={t('Default Simah')} value={booleanEnums[response?.defaultSimah]||'null'} disable={true}/>
         <Input name={t('First Mortgage')} value={booleanEnums[response?.firstMortgage]||'null'} disable={true}/>
         <Input name={t('Redf')} value={booleanEnums[response?.redf]||'null'} disable={true}/>
         <Input name={t('Mobile Verified')} value={booleanEnums[response?.mobileVerified]||'null'} disable={true}/>
         <Input name={t('Family Members')} value={response?.familyMembers||'null'} disable={true}/>
         <Input name={t('Created At')} value={correctDate(response?.createdAt)||'null'} disable={true}/>
         <Input name={t('Update At')} value={correctDate(response?.updateAt)||'null'} disable={true}/>
         
        </div>
       
      </CustomTabPanel1>
      <CustomTabPanel1 value={value} index={2}>
       
  {response?.works?.map(i=> <>
        <p>{t('Employment info')}</p>
      <hr/>
      <div className='info-container'>
        <div className='input-panels'> 
        <Input name={"Work Sector"} value={workSector[i?.workSector]||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Employer Name"} value={i?.employerName||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         <div className='input-panels'> 
        <Input name={"Work SubSector"} value={i?.workSubSector||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"militaryRank"} value={Miltary[i?.militaryRank]||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Is Pilot"} value={booleanEnums[i?.isPilot]||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         <div className='input-panels'> 
        <Input name={"Job Start Date"} value={correctDateWithoutMinutes(i?.jobStartDate)} disable={true} style={{width:'100%'}}/>
         <Input name={"Current Job Start Date"} value={i?.currentJobStartDate||'null'} style={{width:'100%'}} disable={true}/>
         </div>
        </div>
        <p>{t('Salary')}</p>
      <hr/>
      <div className='info-container'>
        <div className='input-panels'> 
         <Input name={"Bank Salary"} value={i?.salaryBank||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Net Salary"} value={formatNumberWithCommas(+i?.netSalary)||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Basic Salary"} value={formatNumberWithCommas(+i?.basicSalary)||'null'} style={{width:'100%'}} disable={true}/>
        </div>
        </div>
        </>)}
        
      
      </CustomTabPanel1>
      <CustomTabPanel1 value={value} index={3}>
    
        {response?.obligations?.map(i=>    
        <>
          <p>{t('firstTableTabs3')}</p>
          <hr/>
          <div className='info-container'>
       <div className='input-panels'> 
        <Input name={"Types"} value={obligationsEnum[i?.obligationType]||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Ammount"} value={formatNumberWithCommas(+i?.amount)||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Months Remaining"} value={i?.monthsRemaining||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Rate"} value={i?.rate||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         <div className='input-panels'> 
        <Input name={"Status"} value={i?.status||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Loan Total Amount"} value={correctDateWithoutMinutes(i?.loanTotalAmount)||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Loan Start Date"} value={correctDateWithoutMinutes(i?.loanStartDate)||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Loan Duration"} value={correctDateWithoutMinutes(i?.loanDuration)||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         </div>
         </>
         )}
      </CustomTabPanel1>
      <CustomTabPanel1 value={value} index={4}>
      {response?.advertisers?.map(i=>    
        <>
          <p>{t('firstTableTabs4')}</p>
          <hr/>
          <div className='info-container'>
       <div className='input-panels'> 
        <Input name={"Listing Id"} value={i?.listingId||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"listing URL"} value={i?.listingURL||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Advertiser Name"} value={i?.advertiserName||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Advertiser Phone"} value={i?.advertiserPhone||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         <div className='input-panels'> 
        <Input name={"AdvertiserType"} value={i?.advertiserType||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Licensed By Aqar"} value={i?.licensedByAqar||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Property Value"} value={formatNumberWithCommas(+i?.propertyValue)||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Property Type"} value={simplifiedTypes?.find(item=>item?.value == i?.propertyType)?.name||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         <div className='input-panels'> 
        <Input name={"Property City"} value={i?.propertyCity||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Is In Project ?"} value={booleanEnums[i?.is_in_project]||'null'} style={{width:'100%'}} disable={true}/>
         <Input name={"Created At"} value={correctDate(i?.createdAt)||'null'} disable={true} style={{width:'100%'}}/>
         <Input name={"Update At"} value={correctDate(i?.updateAt)||'null'} style={{width:'100%'}} disable={true}/>
         </div>
         </div>
         </>)}
      </CustomTabPanel1>
      <CustomTabPanel1 value={value} index={5}>
      <Tabs  
        value={value2}
        onChange={handleChange2}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example">
          <Tab  label={t('limits')}  onClick={()=>{setName('Limits')
          setSubKey('limit')
      }} />
          <Tab label={t('logs')}  onClick={()=>{setName('Logs')
          setSubKey("logs")
      }} {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel2 value={value2}  index={0}>
  
     {info?.lastetScores?.Limit?Object?.values(info?.lastetScores?.Limit)?.sort((a,b)=>b.limit-a.limit)?.map((i)=>
               <Card sx={{ width:275,margin:'20px' }}>
               <CardActionArea>
                 <CardMedia
                   className='img-component'
                   component="img"
                   height="60px"
                   image={banks[i?.bank?.replaceAll(' ','')]}
                 />
                 <CardContent>
                 <div className='priceinfoconstainer'>
                   <span>Max Loan ammount</span>
                   <p className='pricecount'>{formatNumberWithCommas(i?.limit?i?.limit:'0')} SAR</p>
                 </div>
                 <ul className='cardIndo'>
                   <li><div className='inforows'><h4>{i?.morthgageGraphic&&formatNumberWithCommas(Object.entries(i?.morthgageGraphic)?.[0]?.[1])} SAR</h4><span>monuntly innistalment</span></div></li>
                   <li><div className='inforows'><h4>{i?.Tenure/12} years</h4><span>loan tenure</span></div></li>
                   <li><div className='inforows'><h4>{i?.rate} %</h4><span>interest rate</span></div></li>
                 </ul>
                 </CardContent>
               </CardActionArea>
               <CardActions sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
                 <Button size="small" sx={{textTransform:'capitalize',fontSize:'18px'}} color="primary">
                     View Detalis  
                 </Button>
               </CardActions>
             </Card>
            ):info?.lastetScores.Rejected&&!info?.lastetScores.Limit?Object?.entries(info?.lastetScores?.minReqCodes).map((i)=>
            <Card sx={{ width : 250 ,margin:'20px' }}>
            <CardActionArea>
              <CardMedia
                className='img-component'
                component="img"
                height="60px"
                image={banks[i?.[0]?.replaceAll(' ','')]}
              />
              <CardContent>
              <div className='priceinfoconstainer'>
                <span>{i?.[1]?.codes}</span>
                <h3 className='pricecount'>{i18n.language=='en'?i?.[1].reason_en:i?.[1].reason_ar}</h3>
              </div>
              </CardContent>
            </CardActionArea>
            <CardActions sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
              <Button size="small" sx={{textTransform:'capitalize',fontSize:'18px'}} color="primary">
                  View Detalis  
              </Button>
            </CardActions>
          </Card>):null} 
       
           <div></div>
        </CustomTabPanel2>
        <CustomTabPanel2 value={value2} index={1}>
          <h1>{t('logs')}</h1>
          <Editor 
                className="editor"
                height='500px'
                autoInden={true}
                onMount={handleEditorDidMount}
                fontSize="small"
                theme="vs-dark"
                 language="json"
                value={JSON.stringify(response)} /> 
        </CustomTabPanel2>
      </CustomTabPanel1>
      <CustomTabPanel1 value={value} index={6}>
      <p>{t('Attach File')}</p>
          <hr/>
      <Upload id = {id} importFinish={RenderData}/>
       <div className='card-container'>
    {info?.attachments?.map((i)=>   
    <Card sx={{ width: 345,margin:'10px'}}>
       <a  className='link-file' href={process.env.REACT_APP_API_URI+'/'+i.url} target="_blank"> 
      <CardContent>
        <Typography gutterBottom variant="h4" sx={{fontSize:'15px',textOverflow:'ellipsis',maxWidth:'300px',textDecoration:'none',whiteSpace:'nowrap',overflow:"hidden"}} component="div">
          {i.url.split('/')[2].split('.')[1]}
        </Typography>
     
      </CardContent>
      </a> 
      <CardActions sx={{justifyContent:'end'}}>
        <DeleteIcon sx={{cursor:'pointer'}} size="small" color="error" onClick={()=>{
          setKey(i.id) 
          setShowDeletePopup(true) }}>Delete</DeleteIcon>
      </CardActions>
    </Card> )}
       </div>
 
      </CustomTabPanel1>
        </Box>
       {showDeletePopup?<DeletePopup close={setShowDeletePopup} delete={DeleteItem} id={key}/>:null}
    </div>
}