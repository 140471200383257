import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import * as Validator from "../../utils/validation";
import Link from "../../parts/Link";
import { AUTH_API_PATH } from "./apiPath";
import "./Auth.css";
import { Typography } from "@mui/material";

export default function SignIn() {
  const [isValidEmail, setValidEmail] = useState(true);
  const [isValidPassword, setValidPassword] = useState(true);

  const { setUser, user } = useContext(UserContext);

  const navigate = useNavigate();


  useEffect(() => {
    if (user) {
      navigate("/leads");
    }
  }, [user]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      target: { email: emailValue, password: passWordValue },
    } = event;

    const email = emailValue.value;
    const password = passWordValue.value;

    if (isValidEmail && isValidPassword && email && password) {
      axios
        .post(AUTH_API_PATH + "/login", {
          email,
          password,
        })
        .then((data) => {
          setUser(data);
          navigate("/leads");
        })
        .catch((error) => {
       
          toast.error(error.response.data.message);
        });
    }
  };

  const checkValidEmail = ({ target }) =>
    setValidEmail(Validator.isValidEmail(target.value));

  const checkValidPassword = ({ target }) =>
    setValidPassword(Validator.lengthMoreThan(target.value, 7));

  return (
    <>
      <div className="parentDiv">
        <img
          className="authIcon"
          src="https://static.ucraft.net/fs/ucraft/userFiles/munaaseb/images/1605-munaaseb-17010925088494.webp"
        ></img>
        <div className="childDiv">
          <Container component="main" maxWidth="xs">
            <Box className="parentForm">
              <Box component="form" onSubmit={handleSubmit} noValidate>
              <Box >
                <TextField
                  className="input-email"
                  margin="normal"
                  fullWidth
                  id="email"
                  required
                  label="Email"
                  name="email"
                  autoComplete="email"
                  error={!isValidEmail}
                  onBlur={(e) => checkValidEmail(e)}
                />
                {isValidEmail ? null : <p className="error-message">E-mail is incorrect please try again</p>}
                </Box>
                <Box>
                <TextField
                  className="input-password"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  required
                  id="password"
                  autoComplete="password"
                  error={!isValidPassword}
                  onBlur={(event) => checkValidPassword(event)}
                />
                {isValidPassword ? null : <p className="error-message">Password is incorrect please try again</p>}
                </Box>
                <div className="loginLinkDiv">
                  <FormControlLabel
                    className="formController"
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Grid className="parentGrid" container>
                    <Grid className="childGrid" item xs>
                      <Link to="/forgot" text="Forgot Password?"></Link>
                    </Grid>
                  </Grid>
                </div>
                <Button
                  className="loginButton"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}
