import * as React from "react";
import { useContext, useState } from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import "./Menu.css";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LogOutPanel from "./LogOutPanel";
import SettingsIcon from '@mui/icons-material/Settings';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useParams } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";


const Menu = () => {
  const {t} = useTranslation()
  const {id} = useParams()
  console.log("id",id)
  const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'))
  const { resetUser } = useContext(UserContext);
  const [openLogOutPanel, setOpenLogOutPanel] = useState(false);
  const handleOpenLogOutPanel = () => {
    setOpenLogOutPanel(!openLogOutPanel);
  };
  const menuList = [
    {
      label: t('navText1'),
      path: "/leads",
      subPath:`/lead/${id}`,
      icon: <PersonIcon fontSize="small" />,
    },
  
    {
      label: t("Settings"),
      path: "/settings",
      icon: <SettingsIcon fontSize="small" />,
    },
    {
      label: t('navText4'),
      path: "/applications",
      icon: <PersonIcon fontSize="small" />,
    },
    {
      label: `${t('navText4')}-2 `,
      path: "/applications2",
      icon: <PersonIcon fontSize="small" />,
    },

  ];
  const AdminMenuLisit = [
    {
      label: t('navText1'),
      path: "/leads",
      subPath:`/lead/${id}`,
      icon: <PersonIcon fontSize="small" />,
    },
  
    {
      label: t("Settings"),
      path: "/settings",
      icon: <SettingsIcon fontSize="small" />,
    },
    {
      label: t('navText4'),
      path: "/applications",
      icon: <PersonIcon fontSize="small" />,
    },
    {
      label: `${t('navText4')}- 2`,
      path: "/applications2",
      icon: <PersonIcon fontSize="small" />,
    },
    {
      label: t('navText2'),
      path: "/user",
      icon: <AppsIcon fontSize="small" />,
    },
  ];
  return (
    <Box
      className="menuOverlead"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
   
      <Box className="menuList">
        <Box className="navigateBar">
          {(user?.role==1?AdminMenuLisit:menuList)?.map((menuItem) => (
            <Link
              className={menuItem.path === window.location.pathname||menuItem?.subPath == window.location.pathname?"linkBtn":''}
              underline="none"
              key={menuItem.path}
              onClick={() => {
                navigate(menuItem.path);
              }}
            >
              <Button
                color={
                  menuItem.path === window.location.pathname
                    ? "inherit"
                    : "primary"
                }
                className="menuButtons"
              >
                <Box className="icon">{menuItem.icon}</Box>
                <Box className="link">{menuItem.label}</Box>
              </Button>
            </Link>
          ))}
        </Box>
        <footer className="navigateBar">
          <Button className="menuButtons" onClick={handleOpenLogOutPanel}>
            <Box className="icon">
              <LogoutIcon fontSize="small" />
            </Box>
            <Box className="link">{t('logOut')}</Box>
          </Button>
        </footer>
        {openLogOutPanel ? (
          <LogOutPanel
            handleOpenLogOutPanel={handleOpenLogOutPanel}
            resetUser={resetUser}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Menu;
