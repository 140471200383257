import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from 'react-i18next';
import "./Header.css";

const Header = ({ handleTogglePanel }) => {
  const {i18n} = useTranslation()
  

  const setLanguage = (lang = 'en')=>{
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  }

  
return (
  <Box>
    <AppBar className="header" style={{background: "#FFF"}} position="static">
      <Toolbar>
        <IconButton
          className="menuIcon"
          onClick={handleTogglePanel}
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <a href="#">
          <img
            alt="icon"
            className="headerIcon"
            src="https://static.ucraft.net/fs/ucraft/userFiles/munaaseb/images/1605-munaaseb-17010925088494.webp"
          ></img>
        </a>
      </Toolbar>
      <span className="lg" onClick={()=>setLanguage(i18n.language=='ab'?'en':'ab')}>{i18n.language==='en'?'العربية':'English'}</span>
    </AppBar>
  </Box>
)};
export default Header;
