import React, { useState } from 'react'
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import uq from '@umalqura/core';
import { useTranslation } from 'react-i18next';
import MenuItem from "@mui/material/MenuItem";
import { loanTypesAb, nationalityListWithOptions, salaryOptionsAb,MilitaryArray,MilitaryArrayAb} from "./../../utils/options";
import { nationalityListWithOptionsArabic } from "./../../utils/options";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import api from "./../../utils/api";
import { toast } from "react-toastify";


const obligationsEnum = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Car" },
    { value: 3, label: "Mortgage" },
    { value: 4, label: "Credit card" },
    { value: 5, label: "Emkan" },
    { value: 6, label: "Total" },
  ];
const mounthEnum = [
    { value: 1, label: "al-Muḥarram" },
    { value: 2, label: "Ṣafar" },
    { value: 3, label: "Rabīʿ al-ʾAwwal" },
    { value: 4, label: "Rabīʿ ath-Thānī" },
    { value: 5, label: "Jumādā al-ʾŪlā" },
    { value: 6, label: "Jumādā ath-Thāniyah" },
    { value: 7, label: "Rajab" },
    { value: 8, label: "Shaʿbān" },
    { value: 9, label: "Ramaḍān" },
    { value: 10, label: "Shawwāl" },
    { value: 11, label: "Ḏū al-Qaʿdah" },
    { value: 12, label: "Ḏū al-Ḥijjah" },
  ];
  const simplifiedTypes = [
    { value: 1, name: 'apartment' },
    { value: 2, name: 'land' },
    { value: 3, name: 'villa' },
    { value: 4, name: 'floor' },
    { value: 5, name: 'other' }
  ];
  const  mounthEnumAb = [
    { value: 1, label: "محرم" },
    { value: 2, label: "صفر" },
    { value: 3, label: "ربيع الأول" },
    { value: 4, label: "ربيع الثاني" },
    { value: 5, label: "جمادى الأولى" },
    { value: 6, label: "جمادى الثانية" },
    { value: 7, label: "رجب" },
    { value: 8, label: "شعبان" },
    { value: 9, label: "رمضان" },
    { value: 10, label: "شوال" },
    { value: 11, label: "ذو القعدة" },
    { value: 12, label: "ذو الحجة" },
  ]
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
      left:"auto"
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      border:'1px solid #5A5A5A !important',
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "" : "#1A2027",
      borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const workSectorEnum = [
    { value: 1, label: "Government" },
    { value: 2, label: "Military" },
    { value: 3, label: "Private (SME)" },
    { value: 4, label: "Private (Enterprise)" },
    { value: 5, label: "Retired" },
  ];
  function formatNumberWithCommas(number) {
  
    return number?.toLocaleString('en-US', { useGrouping: true })
  }
function Calculator() {
    const [personalInformation,setPersonalInformation] = useState(null)
    const [mounth,setMounth] = useState('')
    const [workMounth,setWorkmounth] = useState('')
    const [workYear,setWorkYear] = useState('')
    const [work, setWork] = useState(null);
    const [year,setYear] = useState('')
    const [obligationsData, setObligationData] = useState({defaultSimah: false});
    const [dbr,setDbr] = useState(NaN)
    const [mortgage, setMorgage] = useState(null);
    const [responce,setResponce] = useState(null)
    const {t,i18n} = useTranslation()
    const [obligations, setObligations] = useState([ {
        id: new Date(),
        amount: "",
        loanTotalAmount: "",
        loanDuration: "",
        monthsRemaining: "",
        obligationType: 1,
      },{
        id: new Date(),
        amount: "",
        loanTotalAmount: "",
        loanDuration: "",
        monthsRemaining: "",
        obligationType: 1,
      },]);
      const normaliseWork = (work) => {
        let obj = {};
        if (work) {
          for (let key in work) {
            if (key === 'desiredApr') {
              obj[key] = !work[key] ? null : work[key];
            } else if (work[key] || work[key] === false) {
              obj[key] = work[key];
            }
          }
          return obj;
        } else {
          return work;
        }
      }
      const normalizeBody = (body) => {
        let obj = {};
        for (let key in body) {
          if (body[key]) {
            obj[key] = body[key];
          }
        }
        return obj;
      };
      const normaliseObligationData = (obligations) => {
        if (obligations.length) {
          return {
            ...obligationsData,
            obligations: obligations.map((i) => {
              return {
                amount: i.amount,
                loanTotalAmount:i.loanTotalAmount,
                loanDuration: i.loanDuration,
                monthsRemaining: i.monthsRemaining,
                obligationType: i.obligationType,
              };
            }),
          };
        } else {
          return obligationsData;
        }
      };
    const years = () => {
        let arr = [];
        for (let i = 1318; i <= uq(new Date())?._hy - 18; i++) {
          arr.push(i);
        }
        return arr.sort((a, b) => b - a);
      }; 
      const dayFormat = (mounth, year) => {
        return `${year}-${mounth}-1`;
      };
      console.log(work)
      const changeObligationData = (id, name, value) => {
        const obj = obligations.map((i) => {
          if (i.id == id) {
            return { ...i, [name]: value };
          }
          return i;
        });
        setObligations(obj);
      };
      function handleSubmit(event) {
        event.preventDefault();
        setResponce(null)
        api
          .post(
            "leads/calculate-lead",
            normalizeBody({
              personalInformation: normaliseWork(personalInformation),
              work: normaliseWork(work),
              obligationsData: normaliseObligationData(obligations),
              mortgage: normaliseWork(mortgage),
             
            })
          )
          .then((res) => setResponce(res))
          .catch(() => toast.error('error'))
      };
  return (
    <div className='application-container'>
        <form onSubmit={handleSubmit}className="calculator-request">
            <p>Calculator</p>
            <div className='personal-info-container'>
                <div className='datepicker-container'>
                    <p>{t('DOB')}</p>
     <div>
     <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          MM
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="MM"
          size="small"
          value={mounth}
          onChange={(e) => {
            setMounth(e.target.value);
                setPersonalInformation({
                ...personalInformation,
                dobHijri: dayFormat(e.target.value, year),
              });
            
          }}
          required
        >
          {(i18n.language=='en'?mounthEnum:mounthEnumAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          YYYY
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          size="small"
          id="demo-simple-select"
          label="YYYY"
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
 
                setPersonalInformation({
                ...personalInformation,
                dobHijri: dayFormat(mounth, e.target.value),
              });
            
          }}
          required
        >
          {years()?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
                    </div>
     
                </div>
                <div className='obg-info-container' style={{width:'100%'}}>
                <div className='input-select-container'>
                    <p>{t('Subsidized')}</p>
         <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Subsidized')}
          </InputLabel>
          <Select
            size="small"
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Subsidized"
            value={personalInformation?.nationality!=='Saudi'?false:personalInformation?.redf==null?'':personalInformation?.redf}
            disabled={personalInformation?.nationality!=='Saudi'}
            onChange={(e) =>
              setPersonalInformation({
                ...personalInformation,
                redf: e.target.value,
              })
            }
          >
         <MenuItem value={true}>{t('yes')}</MenuItem>
            <MenuItem value={false}>{t('no')}</MenuItem>
         
          </Select>
        </FormControl>
                </div>
                <div className='input-select-container'>
                <p>{t('First Home')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('First Home')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="First Home"
            value={personalInformation?.firstMortgage==null?'':personalInformation?.firstMortgage}
            onChange={(e) =>
              setPersonalInformation({
                ...personalInformation,
                firstMortgage: e.target.value,
              })
            }
            required
          >
           <MenuItem value={true}>{t('yes')}</MenuItem>
            <MenuItem value={'false'}>{t('no')}</MenuItem>
          </Select>
        </FormControl>
                </div>
                <div className='input-select-container'>
                    <p>{t('Nationality')}</p>
                    <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Nationality')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Nationality"
            value={personalInformation?.nationality || ''}
            onChange={(e) =>{
              if(e.target.value!=="Saudi"){
                setPersonalInformation({
                ...personalInformation,
                nationality: e.target.value,
                redf:false
              })
            }else{
                setPersonalInformation({
                ...personalInformation,
                nationality: e.target.value,
              })
            }
          }
            }
            required
          >
            {(i18n.language=='en'?nationalityListWithOptions:nationalityListWithOptionsArabic)?.map((i) => (
              <MenuItem value={i.label}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
                </div>
                </div>
             <div className='obg-info-container'>
                <div className='input-select-container'>
                <p> {t('Desired Apr')}</p>
                <FormControl variant="standard" sx={{maxWidth:'550px',width:'100%'}} >
        <BootstrapInput
          placeholder={t('Desired Apr')}
          value={personalInformation?.desiredApr|| ''}
          onChange={(e) =>  
             {
              setPersonalInformation({
                ...personalInformation,
              desiredApr:+e.target.value?+e.target.value:null
            });
          }}
          id="bootstrap-input"
          type="number"
        />
        
      </FormControl>
     
                </div>
                <div className='input-select-container'>
                <p> {t('Mortgage Duration')}</p>
           <FormControl variant="standard" sx={{maxWidth:'550px',width:'100%'}} >
       
        <BootstrapInput
          placeholder={t('Mortgage Duration')}
          value={personalInformation?.mortgageDuration|| ''}
          onChange={(e) =>  
             {
              setPersonalInformation({
                ...personalInformation,
              mortgageDuration:+e.target.value
            });
          }}
          id="bootstrap-input"
          type="number"
          
        />
        
      </FormControl>
  
                </div>
                <div className='input-select-container'>
                    <p>{t('DBR')}</p>
                <FormControl variant="standard" fullWidth>
        <BootstrapInput
          placeholder={t('DBR')}
          value={dbr|| ''}
          onChange={(e) =>  
             {setDbr(e.target.value);
            setObligationData({
              ...obligationsData,
              dbr:+e.target.value/100
            });
          }}
          id="bootstrap-input"
          type="number"
        />
        
      </FormControl>
                </div>
                </div>           
            </div>
            <div className='work-info-conrainer'>
            <div className='obg-info-container'>
            <div className='input-select-container'>
            <p>{t('Work Sector')}</p>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {t('Work Sector')}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Work Sector"
            value={work?.workSector || ''}
            onChange={(e) =>
              setWork({ ...work, workSector: e.target.value })
            }
            required
          >
            {workSectorEnum?.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
                </div>
                {work?.workSector==2&&<>
                <div className='input-select-container'>
                <p>{t('Rank')}</p>
          <FormControl fullWidth>
            <InputLabel size="small" id="demo-simple-select-label">
              {t('Rank')}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Rank"
              value={work?.militaryRank || ''}
              onChange={(e) =>
                setWork({ ...work, militaryRank: e.target.value })
              }
              required
            >
              {MilitaryArray?.map((i) => (
                <MenuItem value={i.value}>{i.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
                </div>

                <div className='input-select-container'>
                <p>{t('Pilote')}</p>
          <FormControl fullWidth>
            <InputLabel size="small" id="demo-simple-select-label">
              {t('Pilote')}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Pilote"
              value={work?.isPilot || ''}
              onChange={(e) =>
                setWork({ ...work, isPilot: e.target.value })
              }
              required
            >
              <MenuItem value={true}>{t('Yes')}</MenuItem>
              <MenuItem value={false}>{t('No')}</MenuItem>
            </Select>
          </FormControl>
                </div>
                </>}
            </div>
            
      <div className='datepicker-container'>
                    <p>{t('Start of Service')}</p>
     <div>
     <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          MM
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="MM"
          size="small"
          value={workMounth}
          onChange={(e) => {
            setWorkmounth(e.target.value);
                setWork({
                ...work,
                jobStartDateHijri: dayFormat(e.target.value, workYear),
              });
            
          }}
          required
        >
          {(i18n.language=='en'?mounthEnum:mounthEnumAb)?.map((i) => (
            <MenuItem value={i.value}>{i.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          YYYY
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          size="small"
          id="demo-simple-select"
          label="YYYY"
          value={workYear}
          onChange={(e) => {
            setWorkYear(e.target.value);
 
                setWork({
                ...work,
                jobStartDateHijri: dayFormat(workMounth, e.target.value),
              });
            
          }}
          required
        >
          {years()?.map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      
                    </div>

      
                </div>
    <div className='obg-info-container'>
                    <FormControl  fullWidth>
      <InputLabel shrink htmlFor="bootstrap-input">
        {t('Net Salary')}
      </InputLabel>
      <BootstrapInput
         onWheel={(e) => e.target.blur()}
        placeholder={t('Type Net Salary')}
        value={work?.netSalary || ''}
        onChange={(e) => setWork({ ...work, netSalary: e.target.value })}
        id="bootstrap-input"
        type="number"
        required
      />
    </FormControl>
    <FormControl  fullWidth>      
    <InputLabel shrink htmlFor="bootstrap-input">
        {t('Basic Salary')}
      </InputLabel>
      <BootstrapInput
                      onWheel={(e) => e.target.blur()}
        placeholder={t('Type Basic Salary')}
        value={work?.basicSalary || ''}
        onChange={(e) => setWork({ ...work, basicSalary: e.target.value })}
        id="bootstrap-input"
        step={0}
        type="number"
        required
      />
    </FormControl>
    <FormControl  fullWidth>     
     <InputLabel shrink htmlFor="bootstrap-input">
        {t('Housing Allowance')}
      </InputLabel>
      <BootstrapInput
                      onWheel={(e) => e.target.blur()}
        placeholder={t('Type Housing Allowance')}
        value={work?.housingAllowance || ''}
        onChange={(e) =>
          setWork({ ...work, housingAllowance: e.target.value })
        }
        id="bootstrap-input"
        type="number"
        required
      />
    </FormControl>
                    </div>
                  
   
            </div>
            <div className='obligations-info-container'>
               {obligations?.map((i,index)=>
        <div className='obligation-wrapper'>
                 <p>Obligation {index+1}</p>
                <div className='obg-info-container'>
                <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Total Amount')}
              </InputLabel>
              <BootstrapInput
                onWheel={(e) => e.target.blur()}
                placeholder={t('Type Total Amount')}
                type="number"
                value={i?.loanTotalAmount}
                onChange={(e) =>
                  changeObligationData(i.id, "loanTotalAmount", e.target.value)
                }
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Monthly Installment')}
              </InputLabel>
              <BootstrapInput
                 onWheel={(e) => e.target.blur()}
                placeholder={t('Type Monthly Installment')}
                type="number"
                value={i?.amount}
                onChange={(e) =>
                  changeObligationData(i.id, "amount", e.target.value)
                }
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                {t('Tenure')}
              </InputLabel>
              <BootstrapInput
                 onWheel={(e) => e.target.blur()}
                placeholder={t('Type Tenure')}
                value={i?.loanDuration}
                onChange={(e) =>
                  changeObligationData(i.id, "loanDuration", e.target.value)
                }
                type="number"
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
                {t('End Of Loan')}
              </InputLabel>
      <BootstrapInput
         onWheel={(e) => e.target.blur()}
        placeholder={t('End Of Loan')}
        value={i?.monthsRemaining}
        onChange={(e) =>
          changeObligationData(i.id, "monthsRemaining", e.target.value)
        }
        type="number"
        id="bootstrap-input"
      />
    </FormControl>
                </div>
               
            
            
                </div>)}
            </div>
            <div className='mortgage-info-container'>
            <FormControl fullWidth >
              <InputLabel size="small" id="demo-simple-select-label">
                {t('Propert Type')}
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('Nationality')}
                value={mortgage?.type || ''}
                onChange={(e) =>
                  setMorgage({
                    ...mortgage,
                    type: e.target.value,
                  })
                }
              >
                {simplifiedTypes?.map((i) => (
                  <MenuItem value={i.value}>{i.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className='obg-info-container'>
            <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="bootstrap-input">
              {t('Property price')}
            </InputLabel>
            <BootstrapInput
              onWheel={(e) => e.target.blur()}
              placeholder={t('Type Amount')}
              value={mortgage?.propertyPrice || ''}
              onChange={(e) =>
                setMorgage({ ...mortgage, propertyPrice: e.target.value })
              }
              type="number"
              id="bootstrap-input"
            />
            </FormControl>
            <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="bootstrap-input">
            {t('Downpayment available')}
          </InputLabel>
          <BootstrapInput
            onWheel={(e) => e.target.blur()}
            placeholder={t('Type Downpayment')}
            value={mortgage?.downPayment || ''}
            onChange={(e) =>
              setMorgage({ ...mortgage, downPayment: e.target.value })
            }
            type="number"
            id="bootstrap-input"
          />
           </FormControl>
            </div>
            </div>
            <div className='btn'><button type='submit'>Calculate</button></div>
        </form>
        <div className="calculator-responce">
            <div className='left-part-responce'>
                <div>
                    <span>Mortgage Limit {responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.bank : ' '}</span>
                    <p>SAR {formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limit : null)||'0'}</p>
                </div>
                {responce?.score?.response?.Limit ?Object.entries(Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.morthgageGraphic)?.map(i=> 
         <div>
          <span>{t('Installment')} ({i?.[0]})</span>
          <p>SAR {formatNumberWithCommas(i?.[1])||0}</p>
        </div>):null}
            </div>
            <div className='right-part-responce'>
                <div><span>APR</span> <p>{responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.rate:0} %</p></div>
                <div><span>Total profit</span> <p>{formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.Benefit : 0)} SAR</p></div>
                <div><span>Total Payment</span> <p>{formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limit + Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.Benefit: null)||0} SAR</p></div>
                <div><span>Retirement age</span> <p>{responce?.score?.response?.RetirementAge||0} Year</p></div>
                <div><span>Salary after retirement</span> <p>{responce?.score?.response?.salaryAfterRetierement?formatNumberWithCommas(responce?.score?.response?.salaryAfterRetierement):responce?.work?.basicSalary?formatNumberWithCommas(+responce?.work?.basicSalary):'0'} SAR</p></div>
                <div><span>Monthly Subsidy</span> <p>{formatNumberWithCommas(responce?.score?.response?.subsidy)||0} SAR</p></div>
                <div><span>Subsidy Package</span> <p>{formatNumberWithCommas(responce?.score?.response?.packageAmount)||0} SAR</p></div>
                <div><span>Tenure Months</span> <p>{responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.Tenure : 0} Month</p></div>
                <div><span>Mortgage Limit without liabilities</span> <p>{formatNumberWithCommas(responce?.score?.response?.Limit ? Object.values(responce?.score?.response?.Limit).sort((a, b) => b.limit - a.limit)?.[0]?.limitWithoutObligations : 0)} SAR</p> </div>
                <div><span>Downpayment minimum</span> <p>{formatNumberWithCommas(+responce?.mortgage?.propertyPrice * (responce?.score?.response?.downPaymentPercent/100))||'----'} SAR</p></div>
                <div><span>Property Price</span> <p>{formatNumberWithCommas(+responce?.mortgage?.propertyPrice)||'----'} SAR</p></div>
            </div>
        </div>
    </div>
    
  )
}

export default Calculator