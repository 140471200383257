import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { nationalityListWithOptions } from "../../utils/options";
import { MilitaryArray } from "./../../utils/options";
import { alpha, styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { salaryOptions } from "../../utils/options";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import api from "./../../utils/api";
import { createSvgIcon } from "@mui/material/utils";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Cookie } from "@mui/icons-material";
const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);
const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];
const workSector = [
  { value: 1, label: "Government" },
  { value: 2, label: "Military" },
  { value: 3, label: "Private (SME)" },
  { value: 4, label: "Private (Enterprise)" },
  { value: 5, label: "Retired" },
];
const obligationsEnum = [
  { value: 1, label: "Personal" },
  { value: 2, label: "Car" },
  { value: 3, label: "Mortgage" },
  { value: 4, label: "Credit card" },
  { value: 5, label: "Emkan" },
  { value: 6, label: "Total" },
];
const years = () => {
  let arr = [];
  for (let i = 1900; i < new Date().getFullYear() - 18; i++) {
    arr.push(i);
  }
  return arr.sort((a, b) => b - a);
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const fullYear = () => {
  let arr = [];
  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    arr.push(i);
  }
  return arr.sort((a, b) => b - a);
};
function CreateLead() {
  const navigat = useNavigate()
  const [personalINformation, setPersonalINformation] = useState(null);
  const [work, setWork] = useState(null);
  const [obligationsData, setObligationData] = useState(null);
  const [mortgage, setMorgage] = useState(null);
  const [day, setDay] = useState("");
  const [mounth, setMounth] = useState("");
  const [year, setYear] = useState("");
  const [indentified, setIndentified] = useState(false);
  const [debt, setDebt] = useState(false);
  const [obligations, setObligations] = useState([]);

  function calculateAge(birthDate) {
    const currentDate = new Date();
    const dob = new Date(birthDate);

    let age = currentDate.getFullYear() - dob.getFullYear();

    const currentMonth = currentDate.getMonth();
    const birthMonth = dob.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < dob.getDate())
    ) {
      age = age - 1;
    }

    return age;
  }
  const dayFormat = (day, mounth, year) => {
    return `${mounth}/${day}/${year}`;
  };
  const changeObligationData = (id, name, value) => {
    const obj = obligations.map((i) => {
      if (i.id == id) {
        return { ...i, [name]: value };
      }
      return i;
    });
    setObligations(obj);
  };
  const collectDate = (mm, yyyy) => {
    return `${mm}/${"1"}/${yyyy}`;
  };
  const normaliseWork = (work) => {
    let obj = {};
    if (work) {
      for (let key in work) {
        if (work[key]) {
          obj[key] = work[key];
        }
      }
      return obj;
    } else {
      return work;
    }
  };
  const normalizeBody = (body) => {
    let obj = {};
    for (let key in body) {
      if (body[key]) {
        obj[key] = body[key];
      }
    }
    return obj;
  };
  const normaliseObligationData = (obligations) => {
    if (obligations.length) {
      return {
        ...obligationsData,
        obligations: obligations.map((i) => {
          return {
            amount: +i.ammount,
            monthsRemaining: +i.monthsRemaining,
            loanStartDate: i.loanStartDate,
            obligationType: i.obligationType,
          };
        }),
      };
    } else {
      return obligationsData;
    }
  };
  useEffect(() => {
    if (day && months && year) {
      setPersonalINformation({
        ...personalINformation,
        dobGreg: dayFormat(day, mounth, year),
      });
    }
  }, [day, months, year]);

  console.log("personal=>", normaliseWork(personalINformation));
  console.log("work->", normaliseWork(work));
  console.log("mortgage->", normaliseWork(mortgage));
  function handleSubmit() {
    api
      .post(
        "leads/create-admin-lead",
        normalizeBody({
          personalINformation: normaliseWork(personalINformation),
          work: normaliseWork(work),
          obligationsData: normaliseObligationData(obligations),
          mortgage: normaliseWork(mortgage),
        })
      )
      .then(() => navigat('/leads'))
      .catch(() => toast.error('error'))
  };
  return (
    <>
    <div onSubmit={handleSubmit} className="create-lead-container">
      <h1 className="header-text-lead">Calculation</h1>
        <p className="lead-info">+37477779734</p>
        <p className="lead-info">Ara Zargaryan</p>
    </div>
    </>
  );
}

export default CreateLead;
