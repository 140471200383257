export const BankRequestTypes = {
    1:'Check Client',
    2:"Check Account",
    3: "Create Client",
    4: "Create Account",
  };

  export const EkengRequestTypes = {
    1:"General",
    2:"Crime",
  };