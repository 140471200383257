import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import Table from "../../parts/Table/Table";
import api from "../../utils/api";
import SettingsModal from './SettingsModal';
import { IMPORT_STATUS, IMPORT_TYPE } from "./importTypes";
import UploadSharpIcon from "@mui/icons-material/UploadSharp";
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from "@mui/material";
import { useEffect } from "react";
import DeleteRow from "../../parts/DeleteRow";

const cell = [
  {
    field: "id",
  },
  {
    field: "name",

  },
  {
    field: "url",
    sortable: true,
  },
  {
    field: "type",
    sortable: true,
  },
];

function Import() {
  const [isOpenNodeCreate, setIsOpenNodeCreate] = useState(false)
  const [isOpenNodeEdit, setIsOpenNodeEdit] = useState(false)
  const [settingRow, setSettingRow] = useState({})
  const [rows, setRows] = useState([]);
  const [importCells, setImportCells] = useState([])
  const [isValidName] = useState(true)
  const [isValidType] = useState(true)
  const fetch = (cb) =>
    api.get("/settings").then((docs) => {

      setRows(docs);
      cb && cb();
    });

    useEffect(() => {
      // if (cell.length !== 5) {
      //   cell.push({
      //         field: 'delete',
      //         headerName: 'Actions',
      //         cellRenderer: ({ data }) => {
      //             return <DeleteRow
      //                 cell={cell}
      //                 rows={rows}
      //                 afterDelete={fetch}
      //                 fetch={fetch}
      //                 id={data.id}
      //                 link='/slides'
      //             />
      //         }
      //     })
      // }
      setImportCells(cell)
      return () => {
        cell.pop()
        setImportCells(cell)
      }
  }, [])
  const handleEditNodePanel = ({ data: _row }) => {
    setIsOpenNodeEdit(!isOpenNodeEdit)
    setIsOpenNodeCreate(false)
    setSettingRow(_row)
}
const handleNodeEdit = (event) => {
  
  event.preventDefault()
  const { target: { url: urlValue, reqType: reqTypeValue, name: nameValue, key: keyValue, type: typeValue,
      username: usernameValue, password: passwordValue, loginAction: loginActionValue, getDataAction: getDataActionValue } } = event

 

  const url = urlValue.value;
  const name = nameValue.value;
  const type = parseInt(typeValue.value);
  const key = keyValue.value;
   const  body = {
          key,
          url,
          name,
          type
      }

  if (isValidName && isValidType) {
      api.put(`/settings/${settingRow.id}`, body, body.additionalData).then(() => {
          fetch()
          setIsOpenNodeEdit(!isOpenNodeEdit)
          toast.success("Succes!")
      }).catch((E) => {
         
          toast.error("Error!");
      })
  }
}
  return (
    <Box>
      <Box className="import">
      <Box className='import'>
            <Box className='importIcon'><SettingsIcon fontSize='large' /></Box>
            <Box className='importName'>
                <Typography variant='h5'>Settings</Typography>
            </Box>
        </Box>
      </Box>
      {isOpenNodeEdit ? <SettingsModal
                    title="Edit Setting"
                    create={handleEditNodePanel}
                    submit={handleNodeEdit}
                    name={settingRow.url}
                    button="Edit"
                    isEditable={true}
                    _settingCells={cell}
                    settingRow={settingRow}
                /> : null}
      <Table
        rows={rows}
        cells={importCells}
        showCheckBox={false}
        rowClick={handleEditNodePanel}
        id="import"
        height={"calc(100vh - 250px)"}
        onFilterChange={fetch}
      />
      <ToastContainer />
    </Box>
  );
}

export default Import;
