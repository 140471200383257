import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const PublicWrapper = (props) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.user === true) {
      return navigate("/leads");
    }
  }, [userContext.user]);

  return (
    <>
      <div className="page">{props.children}</div>
    </>
  );
};

export default PublicWrapper;
