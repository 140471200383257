export const MilitaryArray = [
    { value: 1, label: "Lieutenant" },
    { value: 2, label: "First Lieutenant" },
    { value: 3, label: "Captain" },
    { value: 4, label: "Major" },
    { value: 5, label: "Lieutenant Colonel" },
    { value: 6, label: "Colonel" },
    { value: 7, label: "Brigadier" },
    { value: 8, label: "General" },
    { value: 9, label: "Marshal" },
    { value: 10, label: "First Marshal" },
    { value: 11, label: "Private" },
    { value: 12, label: "First Private" },
    { value: 13, label: "Corporal" },
    { value: 14, label: "Second Sergeant" },
    { value: 15, label: "Sergeant" },
    { value: 16, label: "First Sergeant" },
    { value: 17, label: "Staff Sergeant" }
  ];
  export const MilitaryArrayAb = [
    { value: 1, label: "ملازم " },
    { value: 2, label: "ملازم أول" },
    { value: 3, label: "نقيب" },
    { value: 4, label: "رائد في الجيش" },
    { value: 5, label: "مقدم " },
    { value: 6, label: "عقيد في الجيش" },
    { value: 7, label: "قائد جنرال" },
    { value: 8, label: "جنرال" },
    { value: 9, label: "مشير " },
    { value: 10, label: "المشير الاول" },
    { value: 11, label: "جندي خاص" },
    { value: 12, label: "جندي خاص صف اول" },
    { value: 13, label: "عريف " },
    { value: 14, label: "رقيب ثاني" },
    { value: 15, label: "رقيب رتبة" },
    { value: 16, label: "رقيب أول" },
    { value: 17, label: "رقيب أول" }
  ];
  export const nationalityListWithOptions = [
    { value: 153, label: "Saudi" },
    { value: 1, label: "Afghan" },
    { value: 2, label: "Albanian" },
    { value: 3, label: "Algerian" },
    { value: 4, label: "American" },
    { value: 5, label: "Andorran" },
    { value: 6, label: "Angolan" },
    { value: 7, label: "Antiguans" },
    { value: 8, label: "Argentinean" },
    { value: 9, label: "Armenian" },
    { value: 10, label: "Australian" },
    { value: 11, label: "Austrian" },
    { value: 12, label: "Azerbaijani" },
    { value: 13, label: "Bahamian" },
    { value: 14, label: "Bahraini" },
    { value: 15, label: "Bangladeshi" },
    { value: 16, label: "Barbadian" },
    { value: 17, label: "Barbudans" },
    { value: 18, label: "Batswana" },
    { value: 19, label: "Belarusian" },
    { value: 20, label: "Belgian" },
    { value: 21, label: "Belizean" },
    { value: 22, label: "Beninese" },
    { value: 23, label: "Bhutanese" },
    { value: 24, label: "Bolivian" },
    { value: 25, label: "Bosnian" },
    { value: 26, label: "Brazilian" },
    { value: 27, label: "British" },
    { value: 28, label: "Bruneian" },
    { value: 29, label: "Bulgarian" },
    { value: 30, label: "Burkinabe" },
    { value: 31, label: "Burmese" },
    { value: 32, label: "Burundian" },
    { value: 33, label: "Cambodian" },
    { value: 34, label: "Cameroonian" },
    { value: 35, label: "Canadian" },
    { value: 36, label: "Cape Verdean" },
    { value: 37, label: "Central African" },
    { value: 38, label: "Chadian" },
    { value: 39, label: "Chilean" },
    { value: 40, label: "Chinese" },
    { value: 41, label: "Colombian" },
    { value: 42, label: "Comoran" },
    { value: 43, label: "Congolese" },
    { value: 44, label: "Costa Rican" },
    { value: 45, label: "Croatian" },
    { value: 46, label: "Cuban" },
    { value: 47, label: "Cypriot" },
    { value: 48, label: "Czech" },
    { value: 49, label: "Danish" },
    { value: 50, label: "Djibouti" },
    { value: 51, label: "Dominican" },
    { value: 52, label: "Dutch" },
    { value: 53, label: "East Timorese" },
    { value: 54, label: "Ecuadorean" },
    { value: 55, label: "Egyptian" },
    { value: 56, label: "Emirian" },
    { value: 57, label: "Equatorial Guinean" },
    { value: 58, label: "Eritrean" },
    { value: 59, label: "Estonian" },
    { value: 60, label: "Ethiopian" },
    { value: 61, label: "Fijian" },
    { value: 62, label: "Filipino" },
    { value: 63, label: "Finnish" },
    { value: 64, label: "French" },
    { value: 65, label: "Gabonese" },
    { value: 66, label: "Gambian" },
    { value: 67, label: "Georgian" },
    { value: 68, label: "German" },
    { value: 69, label: "Ghanaian" },
    { value: 70, label: "Greek" },
    { value: 71, label: "Grenadian" },
    { value: 72, label: "Guatemalan" },
    { value: 73, label: "Guinea-Bissauan" },
    { value: 74, label: "Guinean" },
    { value: 75, label: "Guyanese" },
    { value: 76, label: "Haitian" },
    { value: 77, label: "Herzegovinian" },
    { value: 78, label: "Honduran" },
    { value: 79, label: "Hungarian" },
    { value: 80, label: "I-Kiribati" },
    { value: 81, label: "Icelander" },
    { value: 82, label: "Indian" },
    { value: 83, label: "Indonesian" },
    { value: 84, label: "Iranian" },
    { value: 85, label: "Iraqi" },
    { value: 86, label: "Irish" },
    { value: 87, label: "Israeli" },
    { value: 88, label: "Italian" },
    { value: 89, label: "Ivorian" },
    { value: 90, label: "Jamaican" },
    { value: 91, label: "Japanese" },
    { value: 92, label: "Jordanian" },
    { value: 93, label: "Kazakhstani" },
    { value: 94, label: "Kenyan" },
    { value: 95, label: "Kittian and Nevisian" },
    { value: 96, label: "Kuwaiti" },
    { value: 97, label: "Kyrgyz" },
    { value: 98, label: "Laotian" },
    { value: 99, label: "Latvian" },
    { value: 100, label: "Lebanese" },
    { value: 101, label: "Liberian" },
    { value: 102, label: "Libyan" },
    { value: 103, label: "Liechtensteiner" },
    { value: 104, label: "Lithuanian" },
    { value: 105, label: "Luxembourger" },
    { value: 106, label: "Macedonian" },
    { value: 107, label: "Malagasy" },
    { value: 108, label: "Malawian" },
    { value: 109, label: "Malaysian" },
    { value: 110, label: "Maldivan" },
    { value: 111, label: "Malian" },
    { value: 112, label: "Maltese" },
    { value: 113, label: "Marshallese" },
    { value: 114, label: "Mauritanian" },
    { value: 115, label: "Mauritian" },
    { value: 116, label: "Mexican" },
    { value: 117, label: "Micronesian" },
    { value: 118, label: "Moldovan" },
    { value: 119, label: "Monacan" },
    { value: 120, label: "Mongolian" },
    { value: 121, label: "Moroccan" },
    { value: 122, label: "Mosotho" },
    { value: 123, label: "Motswana" },
    { value: 124, label: "Mozambican" },
    { value: 125, label: "Namibian" },
    { value: 126, label: "Nauruan" },
    { value: 127, label: "Nepalese" },
    { value: 128, label: "New Zealander" },
    { value: 129, label: "Nicaraguan" },
    { value: 130, label: "Nigerian" },
    { value: 131, label: "Nigerien" },
    { value: 132, label: "North Korean" },
    { value: 133, label: "Northern Irish" },
    { value: 134, label: "Norwegian" },
    { value: 135, label: "Omani" },
    { value: 136, label: "Pakistani" },
    { value: 137, label: "Palauan" },
    { value: 138, label: "Panamanian" },
    { value: 139, label: "Papua New Guinean" },
    { value: 140, label: "Paraguayan" },
    { value: 141, label: "Peruvian" },
    { value: 142, label: "Polish" },
    { value: 143, label: "Portuguese" },
    { value: 144, label: "Qatari" },
    { value: 145, label: "Romanian" },
    { value: 146, label: "Russian" },
    { value: 147, label: "Rwandan" },
    { value: 148, label: "Saint Lucian" },
    { value: 149, label: "Salvadoran" },
    { value: 150, label: "Samoan" },
    { value: 151, label: "San Marinese" },
    { value: 152, label: "Sao Tomean" },
    { value: 154, label: "Scottish" },
    { value: 155, label: "Senegalese" },
    { value: 156, label: "Serbian" },
    { value: 157, label: "Seychellois" },
    { value: 158, label: "Sierra Leonean" },
    { value: 159, label: "Singaporean" },
    { value: 160, label: "Slovakian" },
    { value: 161, label: "Slovenian" },
    { value: 162, label: "Solomon Islander" },
    { value: 163, label: "Somali" },
    { value: 164, label: "South African" },
    { value: 165, label: "South Korean" },
    { value: 166, label: "Spanish" },
    { value: 167, label: "Sri Lankan" },
    { value: 168, label: "Sudanese" },
    { value: 169, label: "Surinamer" },
    { value: 170, label: "Swazi" },
    { value: 171, label: "Swedish" },
    { value: 172, label: "Swiss" },
    { value: 173, label: "Syrian" },
    { value: 174, label: "Taiwanese" },
    { value: 175, label: "Tajik" },
    { value: 176, label: "Tanzanian" },
    { value: 177, label: "Thai" },
    { value: 178, label: "Togolese" },
    { value: 179, label: "Tongan" },
    { value: 180, label: "Trinidadian or Tobagonian" },
    { value: 181, label: "Tunisian" },
    { value: 182, label: "Turkish" },
    { value: 183, label: "Tuvaluan" },
    { value: 184, label: "Ugandan" },
    { value: 185, label: "Ukrainian" },
    { value: 186, label: "Uruguayan" },
    { value: 187, label: "Uzbekistani" },
    { value: 188, label: "Venezuelan" },
    { value: 189, label: "Vietnamese" },
    { value: 190, label: "Welsh" },
    { value: 191, label: "Yemenite" },
    { value: 192, label: "Zambian" },
    { value: 193, label: "Zimbabwean" },
  ];
  export const  nationalityListWithOptionsArabic = [
    { value: 153, label: "سعودي" },
    { value: 1, label: "أفغاني" },
    { value: 2, label: "ألباني" },
    { value: 3, label: "جزائري" },
    { value: 4, label: "أمريكي" },
    { value: 5, label: "أندوري" },
    { value: 6, label: "أنغولي" },
    { value: 7, label: "أنتيغويين" },
    { value: 8, label: "أرجنتيني" },
    { value: 9, label: "أرميني" },
    { value: 10, label: "أسترالي" },
    { value: 11, label: "نمساوي" },
    { value: 12, label: "أذربيجاني" },
    { value: 13, label: "جزر البهاما" },
    { value: 14, label: "بحريني" },
    { value: 15, label: "بنغلاديشي" },
    { value: 16, label: "بربادوسي" },
    { value: 17, label: "بربوديون" },
    { value: 18, label: "بتسوانيون" },
    { value: 19, label: "بيلاروسي" },
    { value: 20, label: "بلجيكي" },
    { value: 21, label: "بليزي" },
    { value: 22, label: "بنيني" },
    { value: 23, label: "بوتاني" },
    { value: 24, label: "بوليفي" },
    { value: 25, label: "بوسني" },
    { value: 26, label: "برازيلي" },
    { value: 27, label: "بريطاني" },
    { value: 28, label: "برونايان" },
    { value: 29, label: "بلغاري" },
    { value: 30, label: "بركينابي" },
    { value: 31, label: "بورمي" },
    { value: 32, label: "بروندي" },
    { value: 33, label: "كمبودي" },
    { value: 34, label: "كاميروني" },
    { value: 35, label: "كندي" },
    { value: 36, label: "الرأس الأخضر" },
    { value: 37, label: "وسط أفريقي" },
    { value: 38, label: "تشادي" },
    { value: 39, label: "تشيلي" },
    { value: 40, label: "صيني" },
    { value: 41, label: "كولومبي" },
    { value: 42, label: "جزر القمر" },
    { value: 43, label: "كونغولي" },
    { value: 44, label: "كوستاريكي" },
    { value: 45, label: "كرواتي" },
    { value: 46, label: "كوبي" },
    { value: 47, label: "قبرصي" },
    { value: 48, label: "تشيكي" },
    { value: 49, label: "دانماركي" },
    { value: 50, label: "جيبوتي" },
    { value: 51, label: "دومينيكان" },
    { value: 52, label: "هولندي" },
    { value: 53, label: "تيموري شرقي" },
    { value: 54, label: "إكوادوري" },
    { value: 55, label: "مصري" },
    { value: 56, label: "إماراتي" },
    { value: 57, label: "إستوني" },
    { value: 58, label: "إثيوبي" },
    { value: 59, label: "فيجي" },
    { value: 60, label: "فنلندي" },
    { value: 61, label: "فرنسي" },
    { value: 62, label: "غابوني" },
    { value: 63, label: "غامبي" },
    { value: 64, label: "جورجي" },
    { value: 65, label: "ألماني" },
    { value: 66, label: "غاني" },
    { value: 67, label: "يوناني" },
    { value: 68, label: "جرينادي" },
    { value: 69, label: "غواتيمالي" },
    { value: 70, label: "غيني" },
    { value: 71, label: "غينيا" },
    { value: 72, label: "غينيا-بيساوي" },
    { value: 73, label: "غياني" },
    { value: 74, label: "هايتي" },
    { value: 75, label: "هندوراسي" },
    { value: 76, label: "هنغاري" },
    { value: 77, label: "آيسلندي" },
    { value: 78, label: "هندي" },
    { value: 79, label: "إندونيسي" },
    { value: 80, label: "إيراني" },
    { value: 81, label: "عراقي" },
    { value: 82, label: "أيرلندي" },
    { value: 83, label: "إسرائيلي" },
    { value: 84, label: "إيطالي" },
    { value: 85, label: "جامايكي" },
    { value: 86, label: "ياباني" },
    { value: 87, label: "أردني" },
    { value: 88, label: "كازاخستاني" },
    { value: 89, label: "كيني" },
    { value: 90, label: "كيريباتي" },
    { value: 91, label: "كوري" },
    { value: 92, label: "كويتي" },
    { value: 93, label: "قيرغيزي" },
    { value: 94, label: "لاوسي" },
    { value: 95, label: "لبناني" },
    { value: 96, label: "ليسوتو" },
    { value: 97, label: "ليبرياني" },
    { value: 98, label: "ليبي" },
    { value: 99, label: "ليختنشتين" },
    { value: 100, label: "ليتواني" },
    { value: 101, label: "لوكسمبورغي" },
    { value: 102, label: "مدغشقري" },
    { value: 103, label: "مالاوي" },
    { value: 104, label: "ماليزي" },
    { value: 105, label: "مالديفي" },
    { value: 106, label: "مالي" },
    { value: 107, label: "مالطي" },
    { value: 108, label: "جزر مارشال" },
    { value: 109, label: "موريتاني" },
    { value: 110, label: "موريشيوسي" },
    { value: 111, label: "مكسيكي" },
    { value: 112, label: "ميكرونيزي" },
    { value: 113, label: "مولدوفي" },
    { value: 114, label: "موناكو" },
    { value: 115, label: "منغولي" },
    { value: 116, label: "مونتينيغري" },
    { value: 117, label: "مغربي" },
    { value: 118, label: "موزمبيقي" },
    { value: 119, label: "بورمي" },
    { value: 120, label: "ناميبي" },
    { value: 121, label: "نورويجي" },
    { value: 122, label: "عماني" },
    { value: 123, label: "باكستاني" },
    { value: 124, label: "بالاوي" },
    { value: 125, label: "بنمي" },
    { value: 126, label: "بابوا غينيا الجديدة" },
    { value: 127, label: "باراغوي" },
    { value: 128, label: "بيروفي" },
    { value: 129, label: "فلبيني" },
    { value: 130, label: "بولندي" },
    { value: 131, label: "برتغالي" },
    { value: 132, label: "قطري" },
    { value: 133, label: "روماني" },
    { value: 134, label: "روسي" },
    { value: 135, label: "رواندي" },
    { value: 136, label: "سانت كيتس ونيفيس" },
    { value: 137, label: "سانت لوسيا" },
    { value: 138, label: "سانت فينسنت والغرينادين" },
    { value: 139, label: "ساموا" },
    { value: 140, label: "سان مارينو" },
    { value: 141, label: "ساو تومي وبرينسيبي" },
    { value: 142, label: "سعودي" },
    { value: 143, label: "سنغالي" },
    { value: 144, label: "صربي" },
    { value: 145, label: "سيشيلي" },
    { value: 146, label: "سيراليوني" },
    { value: 147, label: "سنغافوري" },
    { value: 148, label: "سلوفاكي" },
    { value: 149, label: "سلوفيني" },
    { value: 150, label: "جزر سليمان" },
    { value: 151, label: "صومالي" },
    { value: 152, label: "جنوب إفريقي" },
    { value: 153, label: "كوري جنوبي" },
    { value: 154, label: "جنوب السودان" },
    { value: 155, label: "إسباني" },
    { value: 156, label: "سريلانكي" },
    { value: 157, label: "سوداني" },
    { value: 158, label: "سوري" },
    { value: 159, label: "سوازيلندي" },
    { value: 160, label: "سويدي" },
    { value: 161, label: "سويسري" },
    { value: 162, label: "سورينامي" },
    { value: 163, label: "تايلاندي" },
    { value: 164, label: "توغو" },
    { value: 165, label: "تونغاني" },
    { value: 166, label: "ترينيدادي" },
    { value: 167, label: "تونسي" },
    { value: 168, label: "تركي" },
    { value: 169, label: "تركماني" },
    { value: 170, label: "توفالوي" },
    { value: 171, label: "أوغندي" },
    { value: 172, label: "أوكراني" },
    { value: 173, label: "إماراتي" },
    { value: 174, label: "بريطاني" },
    { value: 175, label: "أمريكي" },
    { value: 176, label: "أوروغوياني" },
    { value: 177, label: "أوزباكي" },
    { value: 178, label: "فنزويلي" },
    { value: 179, label: "فيتنامي" },
    { value: 180, label: "يمني" },
    { value: 181, label: "زامبي" },
    { value: 182, label: "زمبابوي" },
    { value: 183, label: "توفالواني" },
    { value: 184, label: "أوغندي" },
    { value: 185, label: "اوكراني" },
    { value: 186, label: "أوروغواي" },
    { value: 187, label: "أوزبكستاني" },
    { value: 188, label: "فنزويلي" },
    { value: 189, label: "فيتنامي" },
    { value: 190, label: "ويلزي" },
    { value: 191, label: "يمني" },
    { value: 192, label: "زامبي" },
    { value: 193, label: "زيمبابوي" }
];
  export const salaryOptions = [ 
  { value: 'Rajhi', label: "Rajhi" },
  { value: 'SNB', label: "SNB" },
  { value: 'Bilad', label: "Bilad" },
  { value: 'Riyadh', label: "Riyadh" },
  { value: 'Fransi', label: "Fransi" },
  { value: 'ANB', label: "ANB" },
  { value: 'SAB', label: "SAB" },
  { value: 'Al Jazirah', label: "Al Jazirah" },
  { value: 'Emirates NBD', label: "Emirates NBD" }
]
export const salaryOptionsAb = [
    { value: 'Rajhi', label: "مصرف الراجحي" },
    { value: 'SNB', label: "البنك الأهلي السعودي" },
    { value: 'Bilad', label: "بنك البلاد" },
    { value: 'Riyad', label: "بنك الرياض" },
    { value: 'Fransi', label: "البنك السعودي الفرنسي" },
    { value: 'ANB', label: "البنك العربي الوطني" },
    { value: 'SAB', label: "البنك السعودي البريطاني - ساب" },
    { value: 'Al Jazirah', label: "بنك الجزيرة" },
    { value: 'Emirates NBD', label: "بنك الإمارات الوطني" },
] 

export const loanTypes = [
  { value: 1, label: "Personal" },
  { value: 2, label: "Car"},
  { value: 3, label: "Mortgage" },
  { value: 4, label: "Credit card" },
  { value: 5, label: "Emkan" }
]
export const loanTypesAb = [
  { value: 1, label: "شخصي" },
  { value: 2, label: "سيارة"},
  { value: 3, label: "عقاري" },
  { value: 4, label: "بطاقة إئتمان" },
  { value: 5, label: "إمكان" }
]