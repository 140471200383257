import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import { Button } from '@mui/material';
import './Otp.css'

function Otp() {
    const [otp,setOtp] = useState('')
  return (
    <div className="parentDiv">
        <img
          className="authIcon"
          src="https://static.ucraft.net/fs/ucraft/userFiles/munaaseb/images/1605-munaaseb-17010925088494.webp"
        ></img>
        <div className="otp-container">
        <span style={{maxWidth:'350px'}}>Please enter the OTP sent to your phone ending XXX-XX-XX-54</span>
         <OtpInput
         value={otp}
         onChange={setOtp}
         numInputs={4}
         renderSeparator={<div className='line'></div>}
         renderInput={(props) => <input style={{background:'none', height:'50px',width:'50px'}} {...props} />}
         />   
               <Button
            sx={{width:'100%'}}
                  className="loginButton"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Verify
                </Button>
        </div>
  
      </div>

  )
}

export default Otp