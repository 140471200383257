import React, { useState,useEffect,useRef} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import api from '../../utils/api';
import { DatePicker } from 'rsuite';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {FormControl, InputLabel} from '@mui/material'

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const names = [
    '100',
    '500',
    '1000',
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

 const  styles = { width: 260, display:'flex', marginBottom: 10,paddingRight:15,};
function Filter(props) {
  const [body,setBody] = useState(null)
  const [query,setQuery] = useState('')
  const { ref, isComponentVisible,setIsComponentVisible} = useComponentVisible(false);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const theme = useTheme();

  const normalizeData = (data) => {
    const normalizeDates = data ? JSON.parse(data): null
 
    return normalizeDates
  }

  useEffect(() => {
    const queryString = location.search;
    console.log(location)
    const params = new URLSearchParams(queryString);
    console.log(params)
    const queryObject = {};
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }
    setBody(normalizeData(queryObject.filter))
    setQuery(queryObject?.filter)
  }, [location]);

  // useEffect(() => {
  //   console.log('body', body);
    
  // }, [body]);

  // const carOptions = Object.entries(props?.cars)?.sort((a, b) => a[1] - b[1])?.map(([key, value]) => (
  //     <MenuItem key={key} value={key}>
  //       {value}
  //     </MenuItem>
  //   ));
   
   
  const handleSearch = (body) => {
    
          if(body){
            setLoading(true)
            api
            .get(`leads?filter=${body}`)
            .then((response) => {
                props.setLeads(response?.leads);
                setLoading(false)
            }).catch(()=>setLoading(false));
          }else{
            api.get(`/leads?filter=${'{"limit":"100"}'}`).then((settings) => {
              props.setLeads(settings.leads?.sort((a,b)=>new Date(b.updateAt) - new Date(a.updateAt)));
              setLoading(false)

          }).catch(()=>setLoading(false))
          }
  };

  const changeState = (key, value) => {
    const newState = {
        ...body,
        [key]: value
        
    }
    if(!value || !value.length||value=='null') {
        delete  newState[key]
    }
    navigate(`/leads?filter=${JSON.stringify(newState)}`)
    localStorage.setItem('leads',JSON.stringify(newState))
  }
  useEffect(()=>{
    const params = localStorage.getItem('leads')
    console.log(params)
    navigate(`/leads?filter=${params||"null"}`)
    handleSearch(params)
  },[])
  const nid = body?.documentNumber || '';
  const source = body?.source || '';
  const id = body?.id || '';
  const phoneNumber = body?.mobileNumber || '';
  const FullNameAr = body?.fullNameAr ||'';
  const FullNameEng = body?.fullNameEng||'';
  const start = body?.start || '';
  const limit = body?.limit || '';
  const end = body?.end || '';
  



  
  return (
    <div  className='filters' >
 <div className='selects' >
    <div  className='select'>
    <TextField value={nid} size='small' type={'number'}   sx={{width:'100%'}} placeholder="NID / Iqama" onChange={(e)=>changeState('documentNumber',e.target.value)}/>
    </div>
      <div className='select'>
        <FormControl fullWidth >
        <InputLabel size='small' id="demo-simple-select-label">Source</InputLabel>
        <Select value={source}  size='small' label='Source' onChange={(e) => changeState('source',e.target.value)}>
          <MenuItem value=''>None</MenuItem>
          <MenuItem value='1'>API</MenuItem>
          <MenuItem value='2'>iFrame</MenuItem>
          <MenuItem value='3'>Website</MenuItem>
          <MenuItem value='4'>CRM</MenuItem>
        </Select>
        </FormControl>
     </div>
     <div  className='select'>
      <TextField value={id} size='small' sx={{width:'100%'}} type={'number'} placeholder="Lead ID" onChange={(e)=>changeState('id',e.target.value)}/>
     </div>

    <div className='select'>
    <TextField value={phoneNumber} size='small'  sx={{width:'100%'}} type={'number'} placeholder="Mobile" onChange={(e)=>changeState('mobileNumber',e.target.value)}/>
    </div>
    <div className='select'>
    <TextField  size='small'  sx={{width:'100%'}} value={FullNameAr} placeholder="Name arabic" onChange={(e)=>changeState('fullNameAr',e.target.value)}/>
     </div>
         <div className='select'>
         <TextField  size='small'  sx={{width:'100%'}}  value={FullNameEng}  placeholder="Name english" onChange={(e)=>changeState('fullNameEng',e.target.value)}/>
         </div>
         <div className='select' style={{position:'relative',paddingRight:0}} ref={ref}>
         <TextField  size='small' sx={{width:'100%'}} value={limit} type={'number'} onFocus={()=>setIsComponentVisible(true)} placeholder="Last XX items" onChange={(e)=>changeState('limit',e.target.value)}/>
          {isComponentVisible &&<div className='DropDown'>
            {names?.map(i=><MenuItem onClick={()=>{changeState('limit',i)
                      setIsComponentVisible(false)
          }}>{i}</MenuItem>)}
          </div>}
         </div>
  </div>

  <div className='ranges'>
 <div>
 <p className='name-range'>Date created Start</p>
  <DatePicker
  size="lg"  style={styles}
  format="yyyy-MM-dd"
  value={new Date(start)=='Invalid Date'?null:new Date(start)}
              onChange={(newValue) => {
                  changeState('start', ""+newValue);
              }}
            />
</div>
<div>
    <p className='name-range'>Date created End</p>
            <DatePicker
            size="lg"  style={styles}
            format="yyyy-MM-dd"
            value={new Date(end)=='Invalid Date'?null:new Date(end)}
                        onChange={(newValue) => {
                          console.log(newValue)
                            changeState('end', ""+newValue);
                        }}
                      />
</div>
<div className='searchBtnContainer'>
        <Button className='searchBtn' sx={{height:'37px',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
           background:'var(--Gray-100, #F2F4F7)'
      }}} onClick={()=>handleSearch(query)} size="small" autoWidth color='success' variant='contained'>
          Search
        </Button>
         {loading? <div className='loading'><CircularProgress  style={{'color': '#07bc0c'}}/></div>:null}
        </div>
         </div>

    </div>
  );
}

export default Filter;