import api from "../utils/api";
import { toast } from "react-toastify";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SettingsModal from "../pages/Settings/SettingsModal";
import { useEffect, useState } from 'react';

const DeleteRow = (props) => {


  const onDelete = (event) => {
    event.stopPropagation();
    api
      .delete(`${props.link}/${props.id}`)
      .then(() => {
        props.afterDelete();
        toast.success("Success!");
      })
      .catch((e) => {
        toast.error(e.response.data.error);
      });
  };


const [isOpenNodeEdit, setIsOpenNodeEdit] = useState(false)

const handleEditNodePanel = (_row) => {
  setIsOpenNodeEdit(!isOpenNodeEdit)
}

  

  return (
    <>
    <div className="actions-wrapper">
      <Button onClick={onDelete} color="error">
        <span>
          <DeleteForeverOutlinedIcon />
        </span>
      </Button>
      </div>
      </>
  );
};

export default DeleteRow;
