import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { CircularProgress, Typography, TextField } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import api from "../../utils/api";
import { IMPORT_TYPE } from "./importTypes";
import Box from "@mui/material/Box";
import "./Import.css";
import { GarageRounded } from "@mui/icons-material";

export default function Upload({ importFinish,id}) {
  const userContext = React.useContext(UserContext);
  const [print, setPrint] = useState(false);
  const [files, setFiles] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('')

  const handelFileChange = (event) => {
    setPrint(event.target.value);
    setFiles(event.target.files);
  };

  const handleSubmit = React.useCallback(
    (e) => {
      setLoading(true);
      e.preventDefault();
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const body = new FormData();
      body.append("file", files[0]);


      api
        .post(`/leads/upload-attachments/${id}`, body,{headers:headers}
        )
        .then(() => {
            importFinish()
          setTitle('')
          setPrint(false)
          setFiles(false)
          toast.success("Success!");
        })
        .catch((e) => {
          toast.error("Passed wrong format of document!");
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userContext, files]
  );

  return (
    <>
      <Box className="importParent">
          <Button sx={{margin: '10px',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
           background:'var(--Gray-100, #F2F4F7)'
      }}}
            size="small"
            color="primary"
            className="choose"
            variant="contained"
            component="label"
          >
            Choose file
            <input
              hidden
              accept="image/*"
              multiple
              value={print || ''}
              type="file"
              onChange={handelFileChange}
            />
          </Button>
        <Typography variant="span">{print}</Typography>
        <Button
          disabled={!files.length || loading}
          sx={{ margin: '10px',background:'var(--Gray-100, #F2F4F7)',color:'#344054','&:hover': {
            background:'var(--Gray-100, #F2F4F7)'
       }}}
          color="primary"
          size="small"
          variant="contained"
          onClick={handleSubmit}
        >
          {loading && <CircularProgress size={20} />}
          Upload
        </Button>
      </Box>
    </>
  );
}
